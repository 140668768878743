@mixin propertyIconHostViewMixin($color) {
    .property-marker {
        .property-marker_host-view-icon {
            rect {
                fill: $color;
                &:first-child {
                    stroke: $color;
                }
            }
            path {
                fill: $color;
            }
            &.property-marker_host-view-icon_vr {
                path {
                    stroke: $color;
                }
            }
        }
    }
}

@mixin hoverMarkerMixin {
    transform: scale(1.05);
    border-color: var(--color-black);
    padding: rem(5) rem(10);
    z-index: 9999999;
    // background-color: $color-vibrant-pink;
    background-color: $color-cn-pink;
    color: $color-white;
    // border-color: $color-white;
    @include propertyIconHostViewMixin($color-white);

    .property-marker_property-type-icon {
        circle {
            stroke: $color-white;
        }
        path {
            fill: $color-white;
        }
    }
}

.listings-map {
    width: 100%;
    height: rem(430);
}


/* html:not(.touch-screen) {
    .map-container:not(.geopage-map-container) {
        .map_marker-cluster:hover {
            opacity: 0;
            transition: .3s ease-in-out;
        }
    }
} */
.map-container {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: rem(450);
    max-width: 100%;
    .gm-style {
        .gm-style-iw-d {
            scrollbar-color: transparent transparent;

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
            }
        }

        iframe + div {
            border: none !important;
        }
    }
    .pin-marker-overlay {
        position: absolute;
        z-index: 99999999;
        cursor: grab;
        .mousedown {
            cursor: grabbing;
        }
    }
   .poi-marker-overlay-container{
        position: absolute;
        &:hover {
            .poi-markerLDP-title {
                visibility: hidden;
            }
        }
        .poi-markerLDP{
            position: absolute;
            top: -85px;
            left: -96px;
        }
        .poi-markerLDP-title {
            background-color: $color-white;
            padding: 10px;
            border-radius: 6px;
            font-weight: 500;
            font-size: 10px;
            color: $color-grey-rare;
            width: 218px;
            z-index: 1000;
        }

        .poi-markerLDP-title::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid white;
          }
        .poi-markerLDP-cicle {
            position: absolute;
            top: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            margin-top: 5px;
        }
    }
    .poi-marker-overlay {
        position: absolute;
        font-family: $primary-font;
        font-weight: 600;
        font-size: rem(12);
        line-height: 150%;
        letter-spacing: 0.01em;
        color: $color-white;
        background-color: $color-slate-grey;
        border: rem(1) solid $color-white;
        box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
        border-radius: rem(6);
        padding: rem(3) rem(7);
        opacity: 0;
        animation: appear .3s ease-in-out forwards;
        &::before {
            content: '';
            width: 0;
            height: 0;
            border-left: rem(7) solid transparent;
            border-right: rem(7) solid transparent;
            border-top: rem(7) solid $color-slate-grey;
            position: absolute;
            bottom: rem(-5);
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }

        .poi-marker_decor {
            position: absolute;
            bottom: rem(-22);
            left: 50%;
            transform: translateX(-50%);
        }

        .poi-marker {
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: rem(5);
            align-items: center;
            max-width: 200px;

            .poi-marker_icon {
                path {
                    fill: $color-white;
                }
            }
        }
    }
    [class$="marker-view"] {
        &:hover {
            z-index: 1;
            outline: none !important;
        }
        &:focus {
            outline: none !important;
        }
    }

    [class$="marker-view"] :active {
        outline: none !important;
    }
    [class$="marker-view"] :focus {
        outline: none !important;
    }

    [class$="marker-view highlight"] {
        z-index: 1;
        outline: none;
        .property-marker-overlay {
            @include hoverMarkerMixin;
            &.property-marker-type-overlay__LISTING_WITHOUT_PRICE {
                background: none;
                padding: initial;
                transform: scale(1.2);
            }
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        .property-marker-overlay :active {
            outline: none !important;
        }
        .property-marker-overlay :focus {
            outline: none !important;
        }

        .place-marker-overlay {
            padding: rem(4);
            width: rem(35);
            height: rem(35);

            &.place-marker-overlay::after {
                visibility: visible;
                border-top-style: solid;
                border-top-width: rem(9);
            }

            &.place-marker-overlay__park::after {
                border-top-color: var(--color-green);
            }

            &.place-marker-overlay__point_of_interest::after {
                border-top-color: var(--color-custom-blue);
            }

            &.place-marker-overlay__tourist_attraction::after {
                border-top-color: var(--color-cn-very-light-pink);
            }

            &.place-marker-overlay__shopping_mall::after {
                border-top-color: var(--color-moderate-orange);
            }

            &.place-marker-overlay__gym::after {
                border-top-color: var(--color-red);
            }
        }
    }

    .marker-view__active {
        z-index: 99999999;
    }

    .property-marker-overlay__show {
        animation: markersAppear .3s ease-in-out forwards;
        opacity: 0;
    }
    .property-marker-overlay :active {
        outline: none;
    }
    .property-marker-overlay {
        .property-marker_property-type-icon {
            path {
                fill: var(--color-black);
            }
        }

        &.property-marker-type-overlay__LISTING_WITHOUT_PRICE {
            // padding: initial;
            // border: none;
            // background: none;
            // box-shadow: none;
            // pointer-events: none;

            .property-marker {
                .property-marker_property-type-icon {
                    margin: 0;
                }
            }
        }
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        & * {
            outline: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        &.viewed {
            // &.property-marker-type-overlay__LISTING_WITHOUT_PRICE {
            //     path {
            //         fill: var(--color-pink-tone);
            //     }
            // }

            // &:not(.property-marker-type-overlay__LISTING_WITHOUT_PRICE) {
                background-color: var(--color-pink-tone);
                border: 1px solid var(--color-black);
                color: var(--color-black);

                circle {
                    stroke: var(--color-white);
                }

                path {
                    fill: var(--color-black);
                }
            // }
        }

        position: absolute;
        color: $color-black;
        // border-style: solid;
        // border-width: 1px;
        // border-color: black;
        font-family: $primary-font;
        font-size: rem(14);
        line-height: rem(18);
        font-weight: 600;
        letter-spacing: .05em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        cursor: pointer;
        background-color: $color-white;
        border: 1px solid var(--color-black);
        border-radius: rem(19);
        // border: rem(1) solid $color-prussian-blue;
        padding: rem(4) rem(9);
        // padding: rem(5) rem(10);
        transition: transform .1s ease-in-out,
                    background-color .1s ease-in-out,
                    opacity .3s ease-in-out;
        box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.12), 0px 8px 8px -4px rgba(0, 0, 0, 0.08);
        // box-shadow: 0 rem(4) rem(6) rem(-4) rgba(24, 39, 75, .12), 0 rem(8) rem(8) rem(-4) rgba(24, 39, 75, .08);
        .property-marker_price {
            div {
                span {
                    background-color: $color-subtle-grey;
                }
            }
        }
        &.property-marker-overlay__active {
            // background-image: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
            background-color: $color-cn-pink;
            // border-color: $color-cn-pink;
            // box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
            transform: scale(1.05);
            border-color: var(--color-black);
            padding: rem(5) rem(10);
            z-index: 99999999;
            color: $color-white;
            // transform: translate(1px, 1px);
            // border-style: none;
            // border-color: $color-white;
            @include propertyIconHostViewMixin($color-white);

            &.property-marker-type-overlay__LISTING_WITHOUT_PRICE {
                background: none;
                padding: initial;
                transform: scale(1.2);
            }
        }
        &.property-marker-overlay__wishlisted, &.property-marker-overlay__wishlisted:hover {
            // background-color: $color-pure-black;
            color: $color-pure-black;
            // border-color: $color-white;
        }
        @media (hover: hover) {
            &:hover {
                @include hoverMarkerMixin;
                // &.property-marker-type-overlay__LISTING_WITHOUT_PRICE {
                //     background: none;
                //     padding: initial;
                //     transform: scale(1.2);
                // }
                // transform: scale(1.05);
                // border: unset;
                // padding: rem(5) rem(10);
                // z-index: 9999999;
                // // background-color: $color-vibrant-pink;
                // background-color: $color-cn-pink;
                // color: $color-white;
                // // border-color: $color-white;
                // @include propertyIconHostViewMixin($color-white);

                // .property-marker_property-type-icon {
                //     circle {
                //         stroke: $color-white;
                //     }
                //     path {
                //         fill: $color-white;
                //     }
                // }
            }
        }

        @include propertyIconHostViewMixin($color-black);
        .property-marker {
            display: flex;
            height: rem(16);
            justify-content: center;
            align-items: center;
            .property-marker_price--loading {
                background: none;
                transform: none;
                position: initial;
                width: auto;
                height: auto;
                z-index: initial;
            }
            &.property-marker__wishlisted {
                .property-marker_wishlist-icon {
                    display: block;
                }
            }
            .property-marker_wishlist-icon {
                display: none;
                margin-left: rem(-5);
                // margin-right: 5px;
                scale: .7;
                path {
                    fill: var(--color-rigid-red);
                    stroke: $color-black;
                    stroke-width: rem(1);
                }
                circle {
                    display: none;
                }
            }

            .property-marker_property-type-icon {
                &.property-marker_property-type-icon__active {
                    circle {
                        stroke: $color-white;
                    }
                    path {
                        fill: $color-white;
                    }
                }

                margin-right: -5px;
                margin-left: 3px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
    &:not(.map-ready) {
        .map-info-container {
            visibility: hidden;
        }
        .search-results-map__loader {
            opacity: 1;
            display: block;
        }
    }
    &.map-ready {
        .map-control,
        .map-zoom-controls,
        .map-poi {
            visibility: visible;
            animation: appear 300ms ease-in-out forwards;
            transform: translateZ(1px);
        }
        //.map-zoom-controls {
            // transform: translateY(-50%) translateZ(1px);
        //}
    }
    // &.map-info-container__active {
        // @media #{map-get($breakpoints, 'x-small')} {
        //     .map-control {
        //         &.map-pin {
        //             top: 51%;
        //         }
        //         &.map-viewtype {
        //             top: 60%
        //         }
        //         &.map-transport {
        //             top: 69%;
        //         }
        //     }
        // }
        // .map-info-container {
        //     &.map-mobile {
        //         transform: translateX(-50%) translateY(0);
        //     }
        // }
    //}
    // &.map-search__active {
    // @media #{map-get($breakpoints, 'x-small')} {
    //     .map-control {
    //         &.map-pin {
    //             top: initial;
    //             bottom: 0;
    //             z-index: 1;
    //             width: 100%;
    //             margin: 0;
    //             max-width: rem(375);
    //             left: 50%;
    //             transform: translateX(-50%);
    //             .search {
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }
    // }
    .map-zoom-controls {
        position: absolute;
        opacity: 0;
        z-index: 1;
        bottom: 0;
        right: 0;
        margin: 16px;
        visibility: hidden;
        box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
        border-radius: rem(7);
        background-color: var(--color-white);
    }
    .map-info-container {
        &.map-info-container__wishlisted {
            .map-info-container_wrap {
                .map-info-container_desc-icon {
                    display: flex;
                }
            }
        }
        &.map-mobile {
            transition: .2s ease-in-out;
            position: relative;
            left: 50%;
            // transform: translateX(-50%) translateY(150%);
            background-color: $color-white;
            z-index: 1;
            bottom: rem(150);
            @media screen and (max-width: 600px) {
                position: absolute;
                bottom: rem(125);
            }
            // max-width: rem(335);
            // width: 100%;
            // box-shadow: 0 rem(2) rem(3) rem(2) rgb(0, 0, 0, .15);
            display: grid;
            grid-template-columns: 100px 1fr;
            column-gap: 12px;
            border-radius: 10px;
            min-width: 334px;
            max-width: 420px;
            width: calc(100vw - 10px);
            height: 144px;
            padding: 5px;
            box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);

            //.map-info-container_img {
            //  img {
                    // border-top-left-radius: rem(6);
                    // border-bottom-left-radius: rem(6);
               //}
            //}
            // .map-info-container_desc {
            //     padding-right: rem(10);
            // }
        }
        // display: grid;
        // grid-template-columns: 100px 312px;
        // column-gap: 12px;
        // border-radius: 10px;
        // width: 334px;
        // // height: rem(90);
        // height: 140px;
        // padding: 5px;
        // box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);

        .map-info-container_img {
            // height: 100%;
            // min-width: rem(90);
            // width: rem(90);
            .lazy-load-image-loaded {
                height: 100%;
            }
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            max-height: 130px;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 100%);
                border-radius: inherit;
            }
            .map-info-container_icon {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                padding: 5px;
                z-index: 9999;
                svg {
                    path{
                        stroke: $color-white;
                        stroke-width: 1px;
                    }
                }
            }
            .map-info-container_icon-selected {
                svg {
                    fill: red;
                    path {
                        stroke: none;
                    }
                }
            }
            .property-card_grs-rating {
                position: absolute;
                bottom: 5px;
                left: 5px;
                background: rgba(255, 255, 255, 0.70);
                backdrop-filter: blur(2px);
                padding: rem(5);
                color: $color-black;
                font-weight: 600;
                font-size: 10px;
                line-height: 150%;
                border-radius: 6px 3px 6px 6px;
                display: block;
                z-index: 1;
                .property-card_grs-rating_number {
                    color: currentColor;
                    background: none;
                    padding: initial;
                    font-weight: inherit;
                    font-size: inherit;
                    line-height: inherit;
                    border-radius: initial;
                }
                .property-card_grs-rating_container {
                    display: none;
                }
            }
            img {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: inherit;
                object-fit: cover;
            }
        }
        .map-info-container_desc {
            // padding: rem(10);
            // padding-right: 0;
            position: relative;
            padding: 5px 0;
            // font-size: rem(14);
            font-family: $primary-font;
            // font-weight: 400;
            // color: $color-black;
            // width: 100%;
            // display: flex;
            // flex-flow: column nowrap;
            // justify-content: space-between;
            text-overflow: ellipsis;
            white-space: nowrap;
            // overflow: hidden;
            .map-info-container_icon {
                position: absolute;
                top: 1px;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 7px;
            }
            .map-info-container_icon-selected {
                svg {
                    path {
                        stroke: #DA1414;;
                    }
                }
            }
            .map-info-container_grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto auto 1fr auto auto;
                width: 100%;
                height: 100%;
                .map-info-container-title {
                    padding-right: 28px;
                    grid-column: 1/3;
                    grid-row: 1;
                    overflow: hidden;
                    color: $color-pure-black;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 150%;
                    max-width: 97%;
                }
                .map-info-containr-twosection {
                    display: flex;
                    text-overflow: unset;
                    white-space: unset;
                    overflow: unset;
                    gap: 10px;
                }
                .map-info-container-property__wrapper{
                    position: relative;
                    display: flex;
                    border: 0.8px solid $color-slate-grey;
                    align-items: center;
                    border-radius: 5px;
                    padding: 4px 10px;
                }
                .map-info-container-property {
                    margin-top: 4px;
                    grid-column: 1/3;
                    grid-row: 2;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    .map-info-container-property__wrapper {
                        flex: 0 0 auto;
                    }
                    .property-card_pay-letter-sticker {
                        flex: 0 1 auto;
                    }
                    .map-info-container-property-type {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 150%;
                        color: $color-black;
                    }
                    .map-info-container-property-stars {
                        height: 15px;
                        margin-left: 5px;
                        svg {
                            position: relative;
                            width: 12px;
                            top: -2px;
                        }
                        path {
                            fill: #EFB008;
                        }
                    }
                }
                .map-info-container-headlines {
                    display: flex;
                    text-overflow: unset;
                    white-space: unset;
                    overflow: unset;
                    .map-info-container-headlines-info {
                        display: flex;
                        align-items: center;
                            svg {
                                width: 20px;
                                g, defs {
                                    path {
                                        fill: $color-slate-grey;
                                    }
                                }
                            }
                        .map-info-container-headlines-info-count {
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 150%;
                            letter-spacing: 0.1px;
                            color: $color-dark-grey;
                        }
                    }
                    .map-info-container-headlines-info-beds {
                        margin-left: 3px;
                    }
                    .map-info-container-headlines-info:not(:last-child)::after {
                        content: "";
                        flex-grow: 1;
                        width: 0.317px;
                        height: 13.317px;
                        background-color: #E9E9EB;;
                        margin-left: 4px;
                        margin-right: 4px;
                    }
                }
                .map-info-container-location {
                    font-size: 10px;

                    * {
                        font-size: inherit;
                    }
                    color: $color-black;
                    line-height: 150%;
                    grid-column: 1/3;
                    grid-row: 4;
                    display: inline-block;
                    flex-wrap: wrap;
                    margin-top: 5px;
                    .breakfast_included {
                        color: #4C35BC;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 113%;
                        letter-spacing: 0.1px;
                        text-overflow: unset;
                        white-space: unset;
                        overflow: unset;
                    }
                    .map-info-container-location-neighborhood {
                        font-weight: 400;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 5px;
                        svg {
                            transform: scale(0.6);
                            margin-right: 2px;
                        }
                    }
                    .map-info-container-location-distance {
                        height: 15px;
                        font-weight: 600;
                    }
                }
                .property-card_distance_withbreakfast {
                    display: flex;
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                    .property-card_distance {
                        width: max-content
                    }
                    * {
                        overflow: unset;
                    }
                }
                .map-info-container-amenities {
                    grid-column: 1/3;
                    grid-row: 5;
                    .landmarkname {
                        max-width: 133px;
                    }
                    .property-card_amenities_rail > div {
                        margin-right: 4px;
                    }
                    .property-card_services_top {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                }
                .map-info-container-amenities-headlines {
                    .property-card_services_top {
                        margin-bottom: 0px;
                    }
                }
            }

            * {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .walk-distance-landmark {
                margin-top: 5px;
            }
            .map-info-container_bottom-wrapper {
                display: grid;
                grid-template-columns: 1fr max-content;
                justify-content: space-between;
                align-items: flex-end;
            }
            .map-info-container_desc-title {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: initial;
            }
            .map-info-container_distance {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                max-width: rem(200);
                color: $color-subtle-grey;
                letter-spacing: 0.02em;
                font-weight: 500;
                font-size: rem(13);
                line-height: rem(20);
                margin-top: rem(5);
            }
            .map-info-container_amenities {
                .property-card_amenities {
                    width: 100%;
                    overflow: hidden;
                    max-height: rem(25);
                    line-height: rem(25);
                }
                .property-card_amenities_rail {
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: flex;
                    flex-flow: row nowrap;
                }
                .property-card_amenities_amenity-entity {
                    opacity: 1;
                    // &:not(:first-child) {
                    //     margin-left: rem(5);
                    // }
                    margin: 0;
                    .property-card_amenities_amenity-entity_icon {
                        opacity: 1;
                    }
                }
                .property-card_amenities_amenity-entity_name-container {
                    display: none;
                }
            }
            .map-info-container_star-rating {
                display: flex;
                align-items: center;
                font-size: 1rem;
                font-family: $primary-font;
                .map-info-container_star-rating-icon {
                    width: 1rem;
                    height: 1rem;
                    margin-right: rem(5);
                    path {
                        fill: $color-primary;
                    }
                }
            }
        }
        .map-info-container_wrap {
            // display: flex;
            // justify-content: space-between;
            .map-info-container_desc-icon {
                display: none;
                min-width: rem(12);
                margin-left: rem(10);
                justify-content: flex-end;
                path {
                    stroke: $color-black;
                    fill: $color-black;
                }
            }
        }
    }
    /*.search-results-map__loader {
        border-radius: rem(20);
        position: absolute;
        top: 0;
        opacity: 0;
        transition: opacity 1ms ease-out;
        &.search-results-map__loader--active {
            opacity: 1;
        }
        &.search-results-map__loader--hidden {
            display: none;
        }
    }*/
    .search-results-map,
    .geopage-map,
    .listing-details-map {
        width: 100%;
        height: 100%;
    }
    .listing-details-map {
        min-height: 100vh;
    }
    .search_container {
        position: absolute;
        width: 100%;
        box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);
        z-index: 2;
        background-color: $color-white;
        border-radius: rem(15);
        max-width: rem(392);
        animation: appear .3s ease-in-out;
        // @media screen and (max-width: 600px) {
        //     top: 10%;
        // }
        .search {
            .search-input_container {
                .close-btn.close-icon_wrapper {
                    right: rem(10);
                }
            }
        }
        .search-input_warapper {
            display: grid;
            grid-template-columns: 1fr min-content;
            grid-column-gap: rem(20);
            margin-left: rem(20);
            margin-top: rem(20);
            margin-right: rem(20);
        }
        .search_container-exit {
            font-family: $primary-font;
            // padding: rem(10);
            border: none;
            background: none;
            outline: transparent;
            display: block;
            margin-left: 90%;
            margin-top: 2%;
            margin-bottom: -20px;
            // width: rem(37);
            cursor: pointer;
            .search_container-exit-icon {
                transform: rotate(90deg);
            }
        }
    }
    .map-control {
        $margin: rem(16);
        $size: rem(29);
        margin-top: $margin;
        margin-left: $margin;
        width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
        border-radius: rem(7);
        border: none;
        outline: none;
        opacity: 0;
        visibility: hidden;
        &:last-child {
            margin-top: rem(5);
        }
        &:hover {
            cursor: pointer;
        }
        &.map-reset-btn {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 1%;
            //background-color: #555759;
            border: rem(1) solid $color-white;
            margin-top: 0;
            .map-search-reset {
                width: 1rem;
                height: 1rem;
                /* path {
                    stroke: $color-white;
                } */
            }
        }
        &.move-to-bottom {
            @media screen and (max-width: 600px) {
                top: 3.2rem; // 6%;
            }
        }
        &.map-pin {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 14%;
            margin-bottom: rem(40);
            min-width: $size;
            min-height: $size;
            width: auto;
            height: auto;
            span  {
                display: flex;
                align-items: center;
            }

            // @media #{map-get($breakpoints, 'x-small')} {
            //     top: 70%;
            // }
            .map-pin_place-name {
                font-family: $primary-font;
                color: var(--color-black);
                font-weight: 400;
                font-size: rem(14);
                max-width: rem(200);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .map-pin-icon {
                margin-left: rem(10);
                margin-right: rem(10);
                width: rem(26);
                height: rem(26);
            }
            .map-pin_place-name,
            .close-btn {
                margin-right: rem(10);
            }
        }
        &.map-viewtype {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 26%;
            // @media #{map-get($breakpoints, 'x-small')} {
            //     top: 78%;
            // }
        }
        /*&.map-transport {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 35%;
            // @media #{map-get($breakpoints, 'x-small')} {
            //     top: 86%;
            // }
        }*/
        &.map-zoom-in,
        &.map-zoom-out {
            margin: 0;
            box-shadow: none;
            .map-control_icon {
                width: rem(14);
                height: rem(14);
                path {
                    stroke-width: rem(2);
                }
            }
        }

        &.map-zoom-in {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        &.map-zoom-out {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
        // &.map-zoom-out {
        //     margin-top: rem(5);
        // }
        // &.map-zoom-in {
        //     position: relative;
        //     &::after, &::before {
        //         content: '';
        //         display: block;
        //         border-bottom: .1rem solid $color-black;
        //         width: 38%;
        //         position: absolute;
        //         top: 50%;
        //         left: 35%;
        //     }
        //     &::after {
        //         transform: rotate(90deg);
        //     }
        // }
        // &.map-zoom-out {
        //     position: relative;
        //     &::before {
        //         content: '';
        //         display: block;
        //         border-bottom: .1rem solid $color-black;
        //         width: 38%;
        //         position: absolute;
        //         top: 50%;
        //         left: 35%;
        //     }
        // }
        /*&.map-fullscreen {
            position: absolute;
            margin: 0 rem(16) 0 0;
            border: .3rem solid transparent;
            transition: border .2s ease-in-out;
            right: 0;
            top: 3%;
            z-index: 1;
            // @media #{map-get($breakpoints, 'small')} {
            //     display: none;
            //     &.map-fullscreen-tablet{
            //         display: block;
            //     }
            // }
            svg {
                position: absolute;
                transition: transform .2s ease-in-out;
                &:first-child {
                    right: 0;
                    top: 0;
                }
                &:last-child {
                    left: 0;
                    bottom: 0;
                    transform: rotate(180deg);
                }
            }
            &.map-fullscreen__active {
                border: .5rem solid transparent;
                svg {
                    &:first-child {
                        transform: rotate(180deg);
                    }
                    &:last-child {
                        transform: rotate(0);
                    }
                }
            }
        }*/
    }
    .bounds-search-trigger {
        position: absolute;
        bottom: rem(20);
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        background-color: $color-white;
        box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
        border-radius: rem(10);
        font-size: rem(14);
        line-height: rem(17);
        padding: rem(3) rem(7);
    }
    .gmnoprint.gm-bundled-control,
    .gm-fullscreen-control {
        display: none;
    }
    .map-marker {
        .price-label {
            background: #6658b0;
            font-size: rem(14);
            border-radius: rem(2);
            min-width: rem(40);
            text-align: center;
            padding: rem(4) rem(5) rem(3);
            position: relative;
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(17);
            color: $color-white;
        }
        &.visited {
            .price-label {
                background-color: #848484;
            }
        }
    }
    .map_marker-cluster {
        &.map_marker-cluster__active {
            z-index: 9999999 !important;
            transform: scale(1.1);
            //background-image: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
            background-color: $color-cn-pink;
        }
        background: $color-black;
        color: $color-white;
        border-radius: 50%;
        font-weight: bold;
        font-size: rem(12);
        display: flex;
        align-items: center;
        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: rem(2) solid $color-white;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            background: transparent;
            border-radius: 50%;
        }
        &:hover {
            z-index: 9999999 !important;
            transform: scale(1.1);
        }
        &:after {
            box-shadow: 0 0 0 rem(11) rgba(36, 37, 38, .1);
        }
    }
    // &:not(.geopage-map-container) {
    //     .map_marker-cluster {
    //         &:hover {
    //             opacity: 0;
    //             transition: .3s ease-in-out;
    //         }
    //     }
    // }
    .gm-style-iw-a {
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
        &.map-property-marker,
        &.map-clusterer-info {
            opacity: 1;
            .gm-style-iw.gm-style-iw-c {
                padding: 0;
                border-radius: rem(6);
                max-width: initial !important;
            }
        }
        &.map-geo-title {
            opacity: 1;
            .gm-style-iw.gm-style-iw-c {
                border-radius: rem(10);
                padding: rem(8) rem(19) !important;
                background: $color-black;
                font-size: rem(12);
                color: $color-white;
                font-family: $primary-font;
            }
            // .gm-style-iw-t::after {
            //     height: rem(7);
            //     width: rem(7);
            //     background: $color-black;
            // }
            .gm-style-iw-tc::after {
                // opacity: 0;
                background: $color-black;
            }
        }
        &.map-property-marker {
            cursor: pointer;
            .gm-style-iw-t::after {
                // transform: translate(-450%,-50%) rotate(-45deg);
                transform: translate(-450%, -9px) rotate(-45deg);
            }
            .gm-style-iw.gm-style-iw-c {
                height: rem(90);
            }
        }
        &.map-clusterer-info {
            .gm-style-iw.gm-style-iw-c,
            .gm-style-iw-t::after {
                background: $color-black;
            }
            .map-clusterer-container {
                padding: 0.4rem 0.6rem;
                font-family: $primary-font;
                color: $color-white;
                font-weight: 400;
                font-size: rem(12);
                line-height: 1.1rem;
                span {
                    opacity: .3;
                }
            }
        }
    }
    .close-btn {
        cursor: pointer;
    }
    .search {
        cursor: initial;
        // box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
        border-radius: rem(6);
        // padding-top: rem(20);
        padding-bottom: rem(20);
        font-family: $primary-font;
        font-weight: 400;
        font-size: rem(16);
        .search-input_container {
            position: relative;
            // margin-left: rem(20);
            // margin-right: rem(20);
            .search-icon,
            .close-icon_wrapper {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            .search-icon {
                left: 20px;
                path {
                    fill: $color-gray-font;
                }
            }
            .close-icon_wrapper {
                right: 6px;
                border: none;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
                right: rem(13);
                cursor: pointer;
                background-color: $color-light-gray-input;
                border-radius: 50%;
                width: rem(24);
                height: rem(24);
                .close-icon {
                    width: rem(10);
                    height: rem(10);
                    path {
                        stroke: #797882;
                        stroke-width: rem(2);
                    }
                }
            }
            .search_input {
                font-family: $primary-font;
                font-weight: 400;
                line-height: 150%;
                // transition: .3s;
                width: 100%;
                background-color: #E9E9EB;
                outline: none;
                border-radius: 25px;
                padding: 11px 42px 11px 52px;
                height: auto;
                text-overflow: ellipsis;
                white-space: nowrap;
                // padding-right: rem(30);
                // padding-left: rem(30);
                color: $color-black;
                // box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, .2);
                border: rem(1) solid $color-subtle-grey;
                &:focus {
                    background-color: $color-white;
                }
            }
        }
        .search_empty-title {
            font-weight: 600;
            font-size: rem(16);
            line-height: 150%;
            text-align: center;
        }
        .search_empty {
            margin-top: rem(20);
            text-align: center;
            padding-left: rem(20);
            padding-right: rem(20);
            display: flex;

            .search_empty-desc {
                color: $color-gray-font;
                text-align: left;
                font-size: 14px;
                line-height: 150%;
            }
            svg{
                width: 38%;
                margin-top: 5px;
            }
        }
        .search_suggestions {
            margin-top: rem(10);
            padding-left: rem(5);
            padding-right: rem(5);
            .search_suggestion {
                display: grid;
                grid-template-columns: rem(50) 1fr;
                font-family: $primary-font;
                text-align: left;
                border-radius: rem(6);
                cursor: pointer;
                width: 100%;
                &:hover {
                    background-color: #EEEEF0;
                }
                * {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 100%;
                }
                .search_suggestion_icon {
                    width: rem(26);
                    height: rem(26);
                    justify-self: center;
                    align-self: center;
                }
                .search_suggestion_desc {
                    // line-height: rem(40);
                    padding-top: rem(15);
                    padding-bottom: rem(15);
                    padding-right: rem(20);
                    line-height: rem(20);
                    .search_suggestion_secondary {
                        font-size: rem(14);
                        line-height: rem(15);
                        color: $color-gray-font;
                    }
                }
            }
            .search_suggestion__highlight {
                font-weight: 500;
            }
        }
    }
    &.geopage-map-container {
        .search_container {
            left: 1.25rem;
            @media (max-width: 1136px) and (min-width: 601px) {
                top: 3%;
                width: 85%;
            }
            @media (min-width:1136px) {
                top: 14%;
            }
            @media #{map-get($breakpoints, 'x-small')} {
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .map-pin {
            top: 3%;
            z-index: 1;
            margin-top: 0;
            @media #{map-get($breakpoints, 'x-small')} {
                top: 14%;
            }
        }
        .map-viewtype {
            top: 9%;
            @media #{map-get($breakpoints, 'x-small')} {
                top: 21%;
            }
        }
        &.map-search__active {
            .map-pin {
                top: 12%;
                @media #{map-get($breakpoints, 'x-small')} {
                    top: initial;
                    bottom: 0;
                }
            }
        }
        .map-poi {
            @media #{map-get($breakpoints, 'x-small')} {
                left: 50%;
                transform: translateX(-50%);
                margin-left: 0;
            }
            position: absolute;
            bottom: 3%;
            opacity: 0;
            z-index: 1;
            display: flex;
            background: $color-white;
            box-shadow: 0 rem(4) rem(10) rgba(0, 0, 0, .15);
            border-radius: rem(6);
            margin-left: rem(20);
            .map-poi-item {
                outline: none;
                &:first-child {
                    border-top-left-radius: rem(6);
                    border-bottom-left-radius: rem(6);
                }
                &:last-child {
                    border-top-right-radius: rem(6);
                    border-bottom-right-radius: rem(6);
                }
                &:not(:last-child) {
                    margin-right: rem(10);
                }
                display: flex;
                justify-content: center;
                align-items: center;
                width: rem(44);
                height: rem(44);
                border: none;
                background: $color-white;
                cursor: pointer;
            }
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@keyframes markersAppear {
    from {
        opacity: 0;
        transform: scale(.7);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/*.show-map-mble {
    display: none;
    @media screen and (max-width: 600px) {
        width: 8.75rem;
        height: 2.75rem;
        position: fixed;
        top: auto;
        bottom: 0.9375rem;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        @include body-emphasized();
        color: $color-bright-purple;
        border-radius: 0.625rem;
        background: $color-white;
        -webkit-box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 4px 20px -10px rgba(0, 0, 0, 0.15);
        z-index: 2;
        cursor: pointer;
        svg {
            margin-right: 0.625rem;
        }
    }
}*/


/* Custom tooltips */

/*.cn-pin-hint {
    .pin-info-window {
        display: block;
        opacity: 0;
        animation: appear .3s 2s ease-in-out forwards;
    }
}

.pin-info-window {
    display: none;
    z-index: 1;
    user-select: none;
    .pin-info-window_tooltip {
        &.pin-info-window_tooltip-left {
            .pin-info-window_tooltip-close-btn {
                margin-left: 0;
                margin-right: rem(10);
            }
        }
        flex-direction: row-reverse;
        font-family: $primary-font;
        font-size: rem(12);
        letter-spacing: .03em;
        line-height: rem(18);
        display: flex;
        background: rgb(34, 34, 34);
        border-radius: rem(15);
        box-shadow: rgba(0, 0, 0, .28) 0 rem(8) rem(28);
        color: $color-white;
        padding: rem(10) rem(15);
        white-space: pre-line;
        max-width: rem(300);
    }
    .pin-info-window_tooltip-close-btn {
        border: none;
        background: none;
        outline: none;
        width: rem(12);
        height: rem(12);
        margin-top: rem(5);
        margin-bottom: rem(5);
        margin-left: rem(10);
        cursor: pointer;
        .pin-info-window_tooltip-close-icon {
            width: inherit;
            height: inherit;
            path {
                stroke: $color-white;
                stroke-width: 2;
            }
        }
    }
}*/

.marker-info-window {
    opacity: 0;
    animation: appear .3s .1s ease-in-out forwards;
    cursor: pointer;
    z-index: 1;
}
.desktop-marker-info-window_close {
    display: flex;
    justify-content: flex-end;
    margin-right: -5px;
    .close-btn {
        margin-bottom: -15px;
        z-index: 9;
    }
}
.desktop-marker-info-window {
    width: rem(311);
    border-radius: rem(20);
    box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px;
    background-color: $color-white;
    font-family: $primary-font;
    &:hover {
        .desktop-marker-info-window_image-container {
            .swiper-container {
                .swiper-button-prev, .swiper-button-next {
                    opacity: 1;
                }
            }
        }
    }
    .property-card_free-cancellation-sticker {
        z-index: 1;
        position: relative;
    }
    .desktop-marker-info-window_image-container {
        position: relative;
        .property-card_wishlist-marker {
            &.property-card_wishlist-marker__actionable {
                cursor: pointer;
            }

            position: absolute;
            top: 0.9375rem;
            left: 0.9375rem;
            z-index: 1;
            background: none;
            border: none;

            .property-card_wishlist-marker-icon {
                path {
                    stroke: var(--color-white);
                    fill: rgba(0, 0, 0, .5);
                }
                circle {
                    display: none;
                }
                &.property-card_wishlist-marker-icon__selected {
                    path {
                        fill: red;
                        stroke-width: rem(1);
                        stroke: none;
                    }
                }
            }
        }
        .property-card_slider-container {
            position: relative;
            z-index: 0;
        }
        .swiper-container {
            height: rem(165);
            border-top-left-radius: rem(20);
            border-top-right-radius: rem(20);
            -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
            overflow: hidden;
            .swiper-pagination.swiper-pagination-bullets {
                bottom: rem(4);
                .swiper-pagination-bullet {
                    opacity: 1;
                    background-color: $color-white;
                    &.swiper-pagination-bullet-active-main {
                        background-color: $color-cn-pink;
                    }
                }
            }
            .swiper-slide { // slick-slide
                padding: 0;
                max-height: rem(182);
                img {
                    height: rem(182);
                    width: 100%;
                }
            }
            .swiper-button-prev, .swiper-button-next {
                transition: .2s ease-in-out;
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.5rem;
                height: 1.5rem;
                box-shadow: 0 0.0625rem 0.125rem rgba(36, 37, 38, .2);
                background-color: $color-white;
                border-radius: 50%;
                background-color: $color-white;
                top: 55%;
                &::after {
                    font-size: rem(14);
                    color: $color-black;
                    font-weight: 900;
                }
            }
        }
        &__tags{
            position: absolute;
            top: 0.9375rem;
            right: 0.9375rem;
            display: flex;
            justify-content: flex-end;
            gap: 5px;
            flex-direction: column;
            align-items: flex-end;

            .property-card_breakfast-included-sticker{
                z-index: 1;
                width: unset;
                p{
                    display: flex;
                }
            }
        }
    }
    .desktop-marker-info-window_image-slider {
        height: rem(165);
        border-top-left-radius: rem(20);
        border-top-right-radius: rem(20);
        -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
        overflow: hidden;
        .slick-slide {
            padding: 0;
            max-height: rem(182);
            img {
                height: rem(182);
            }
        }
        .slider-nav-control {
            .slider-nav-control_icon {
                transform: rotate(90deg) translateY(.0625rem);
            }
            &.slick-prev {
                left: .625rem;
                .slider-nav-control_icon {
                    transform: rotate(90deg) translateY(.0625rem);
                }
            }
            &.slick-next {
                right: .625rem;
                .slider-nav-control_icon {
                    transform: rotate(-90deg) translateY(.0625rem);
                }
            }
            transition: .2s ease-in-out;
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.5rem;
            height: 1.5rem;
            box-shadow: 0 0.0625rem 0.125rem rgba(36, 37, 38, .2);
            background-color: #fff;
            border-radius: 50%;
            z-index: 1;
        }
        .slick-dots {
            bottom: .3125rem;
            li {
                margin: 0;
                width: rem(15);
                height: rem(15);
                &.slick-active {
                    button::before {
                        background-color: $color-white;
                    }
                }
                button {
                    &::before {
                        width: rem(5);
                        height: rem(5);
                    }
                }
            }
        }
    }
    .desktop-marker-info-window_trunc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .desktop-marker-info-window_wrapper {
        display: flex;
        .desktop-marker-info-window_property-type {
            font-feature-settings: "smcp", "c2sc";
            font-variant: small-caps;
            text-transform: uppercase;
            font-size: rem(14);
            letter-spacing: 0.7px;
            font-weight: 500;
            // color: $color-subtle-grey;
            color: $color-white;
            line-height: rem(17);
            width: auto;
        }
        .desktop-marker-info-window_star-rating {
            display: flex;
            margin-left: rem(5);
            .desktop-marker-info-window_star-rating-icon {
                width: rem(15);
                height: rem(15);
                path {
                    // fill: $color-subtle-grey;
                    fill: $color-white;
                }
            }
        }
    }
    .desktop-marker-info-window_grs {
        border-radius: 10px;
        padding: 3px 12px;
        margin-bottom: 5px;
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(2px);
        display: flex;
        column-gap: 12px;
        font-size: 14px;
        line-height: 150%;
        font-weight: 600;
        color: $color-pure-black;
        font-weight: 500;
        font-size: rem(14);
        opacity: 0;

        .property-card_grs-rating_number {
            font-size: inherit;
            color: inherit;
            padding: 0;
            display: flex;
            align-items: center;
        }

        .grs-rating_container {
            .grs-rating_adjective {
                font-size: inherit;
                color: inherit;
            }
            .grs-rating_reviews {
                color: inherit;
                font-weight: 400;
                font-size: 12px;
            }
        }

        &.desktop-marker-info-window_grs--visible {
            opacity: 1;
        }

        &.property-card_grs-rating {
            opacity: 1;
            // background-color: initial;
            .property-card_grs-rating_number {
                border-bottom-left-radius: rem(5);
            }
            .property-card_grs-rating_container {
                white-space: nowrap;
                //display: none;
                //.property-card_grs-rating_reviews {
                    // color: $color-white;
                //}
            }
        }
    }
    .desktop-marker-info-window_image-attrs {
        display: grid;
        grid-template-columns: max-content min-content;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-left: rem(15);
        padding-right: rem(15);
        margin-bottom: rem(10);

        .property-card_amenities{
            padding-bottom: 0.3125rem;
            grid-area: 1/2;
        }
    }
    .desktop-marker-info-window_desc {
    .property-card_distance_neighbour {
        overflow: hidden;
        max-width: 120px;
        display: -webkit-inline-box;
        display: flow-root;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
        /*.property-card_services_top {
            position: relative;
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            -webkit-align-items: center;
            -moz-box-align: center;
            align-items: center;
            justify-content: flex-start;
            margin-top: 5px;
            flex-wrap: wrap;
            padding-top: 3px;
            padding-bottom: 5px;

            .property-card_services_top_icon {
                position: relative;
                display: grid;
                place-items: center;
                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
        .property-card_services {
            position: relative;

            &_top {
              position: relative;
              gap: 3px;
              grid-row-gap: 3px;
              margin-top: 10px;
              display: grid;
              grid-template-columns: repeat(7, 1fr);
              grid-gap: 3px;
              overflow-x: auto;
              white-space: nowrap;
              -webkit-overflow-scrolling: touch;
              -ms-overflow-style: none;
              scrollbar-width: none;
              cursor: ew-resize;
              &_icon {
                position: relative;
                place-items: center;
                color: #5C6AC4;
                font-size: 10px;
                font-weight: 500;
                line-height: 150%;
                width: 78px;
                text-align: center;
                flex: 0 0 calc(50% - 10px);
                letter-spacing: 0.1px;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                gap: 1px;
                flex-shrink: 0;
                border-radius: 18px;
                border: 1px solid #5C6AC4;
                background: #F0F8FF;
                cursor: ew-resize;
            }
          }
          }*/

        // padding: rem(18);
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(15);
        padding-right: rem(15);
        font-family: $primary-font;
        // padding: rem(5) rem(15) rem(15);
        .desktop-marker-info-window_property-title {
            // margin-top: rem(5);
            // margin-bottom: rem(5);
            font-size: rem(18);
            font-weight: 600;
            // line-height: rem(20);
            color: $color-black;
        }
        .desktop-marker-info-window_property-activities {
            margin-top: rem(7);
            max-height: unset;
            .property-card_activities_rail{
                flex-wrap: wrap;
            }
        }
        .desktop-marker-info-window_property-price{
            font-size: 14px;
            text-align: right;
            font-weight: 400;
            line-height: 21.6px;
            margin-top: 9px;
            color:#797882;
            .desktop-marker-info-window_property_price_bolded{
                font-weight: 600;
                font-size: 18px;
                color:black
            }
        }
        .desktop-marker-info-window_distance {
            display: none;
            &.property-card_distance--active {
                display: flex;
            }
            // &:not(:empty) {
            //     margin-top: rem(5);
            // }
            // font-size: .9rem;
            // max-width: rem(200);
        }
        .desktop-marker-info-window_ratings {
            margin-top: rem(5);
            font-size: 1rem;
            line-height: rem(20);
            color: $color-black;
            .desktop-marker-info-window_star-rating-icon {
                margin-right: rem(5);
                path {
                    fill: $color-primary;
                }
            }
        }
        .property-card_name__type{
            position: relative;

            &.property-card_with_additional_headlines_count {
                display: flex;
                align-items: center;
                gap: 10px;
                @media screen and (max-width: 600px) {
                    margin-bottom: 20px;
                }
                .property-card_additional_headlines_info {
                    display: flex;
                    .property-card_additional_headlines_info_items {
                        margin-left: 0px;
                        width: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:not(:first-child) {
                            border-left:1px solid #E9E9EB;
                        }
                        span {
                            color:#64748B;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        svg {
                            margin-right:4px;
                            &.additional-headlines-info_grey_icon {
                                * {
                                    fill: #64748B;
                                    stroke: none;
                                }
                            }
                        }
                    }
                }


            }

            .property-card_name__type__name{
                position: relative;
                border: 0.3px solid #64748B;
                @media screen and (max-width: 600px) {
                    border: 1px solid #64748B;
                }
                box-sizing: border-box;
                border-radius: 5px;
                display: inline-flex;
                padding: 4px 10px;
                margin: 7px 0;

                .property-card_name__type__name__title{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    letter-spacing: 0.01em;
                    color: #64748B;
                }
                .property-card_type-stars {
                    display: inherit;
                    margin-left: rem(5);

                    path {
                        fill: #EFB008;
                        fill-opacity: 1;
                    }
                }
            }


        }
        .property-card_distance{
            width: 100%;
        }
        .property-card_distance-icon{
            flex-shrink: 0;
        }
        .desktop-marker-info-window_property-wrapper{
            position: relative;
            display: flex;
            align-items: flex-start;
            // margin-top: 15px;
            flex-direction: column;
            .property-card-activitiesandamenities {
                margin-right:10px;
                width:100%;
                min-height: 41px;
            }
            .property-card_price{
                grid-area: 1/2;
                text-align: right;
                font-size: 14px;
                margin-left: auto;
                margin-top: 10px;
                .property-card_price-actual{
                    align-self: baseline;
                    span{
                        font-weight: 600;
                        font-size: 18px;
                    }
                    .property-card_price-actual__night {
                        font-weight: 400;
                        font-size: 14px;
                        color: $color-gray-font;
                    }
                }
                .property-card_price-total{
                    color: $color-gray-font;
                }
            }
        }
    }
}

.place-marker-overlay {
    transition: .3s;
    border: none;
    background: none;
    outline: none;
    text-align: left;
    opacity: 0;
    animation: appear .3s .1s ease-in-out forwards;
    border-radius: 50%;
    width: rem(18);
    height: rem(18);
    padding: rem(2);
    position: relative;

    &::after {
        transition: inherit;
        border-left: rem(9) solid transparent;
        border-right: rem(9) solid transparent;
        border-top: rem(9) solid transparent;
        content: "";
        height: 0;
        left: 50%;
        position: absolute;
        top: 95%;
        transform: translate(-50%);
        width: 0;
        z-index: 1;
    }

    &.place-marker-overlay__park {
        background-color: var(--color-green);
    }

    &.place-marker-overlay__point_of_interest {
        background-color: var(--color-custom-blue);
    }

    &.place-marker-overlay__tourist_attraction {
        background-color: var(--color-cn-very-light-pink);
    }

    &.place-marker-overlay__shopping_mall {
        background-color: var(--color-moderate-orange);
    }

    &.place-marker-overlay__gym {
        background-color: var(--color-red);
    }

    .place-marker-overlay__icon {
        cursor: pointer;

        svg {
            fill: var(--color-white);
            stroke: var(--color-white);
        }
    }
}

.place-marker-overlay__info-window {
    margin-top: rem(8);
    .place-marker-overlay__info-window__title {
        font-family: var(--primary-font);
        font-size: rem(12);
        font-weight: 600;
        line-height: rem(15);
        color: var(--color-black);
    }
}

.map-fancy-loader__centered {
    top: 50%;
    transform: translateY(-50%);
}

.map-fancy-loader__default {
    top: 3%;
}

.map-fancy-loader__map {
    top: 60px;
}
