//------------------- DESKTOP -------------------

.cn__CTA__bookinfo1__wrapper {
    opacity: 0;
    animation: appear .7s ease-in-out forwards;
}

.cn__CTA__bookinfo1{
    border: 1px solid var(--color-grey);
    background: #FFFFFF;
    border-radius: 15px;
    padding: 10px 20px;
    display: flex;
    align-items: center;

    &__icon{
        position: relative;
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border: 14px solid #E9E9EB;
        border-radius: 50%;
        display: grid;
        place-items: center;

        &__circle{
            position: absolute;
            top: -14px;
            left: -14px;

            circle {
                transition: stroke-dashoffset 1s;
                // transform: rotate(-90deg);
                // transform-origin: 50% 50%;
            }
        }

    }

    &__info{
        position: relative;
        width: 100%;
        margin-left: 20px;

        &__title{
            font-weight: 600;
            font-size: 23px;
            line-height: 1.2;
            color: #000000;
        }

        &__subtitle{
            font-size: 18px;
            line-height: 1.2;
            color: #797882;
            margin-top: 3px;
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width: 1099px){

    .cn__CTA__bookinfo1__wrapper{
        padding: 0 20px;
        bottom: 173px;
    }

}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){

    .cn__CTA__bookinfo1__wrapper{
        bottom: 167px;
    }

    .cn__CTA__bookinfo1{
        padding: 20px 16px;

        &__info{
            margin-left: 16px;

            &__title{
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.02em;
            }

            &__subtitle{
                font-size: 11px;
            }
        }
    }

}