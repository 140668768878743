.property-card {
  animation: appear 0.05s ease-in-out;
  width: 100%;
  margin-bottom: rem(40);
  border-radius: rem(20);
  display: grid;
  grid-template-columns: 29.764% 70.236%;
  background-color: $color-white;
  transition: 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  box-shadow: 0 rem(5) rem(30) rgba(231, 228, 228, 0.5);
  overflow: hidden;

  &.property-card__loading {
    .react-loading-skeleton {
      height: 100%;
    }
  }

  &:hover {
    .property-card_slider-container {
      .swiper-button-prev, .swiper-button-next {
        opacity: 1;
      }
    }

    .property-card_image-slider {
      .slider-nav-control {
        opacity: 1;
      }
    }
    .property-card_footer {
      .property-card_rating {
        .property-card_rating-icon {
          path {
            fill: $color-primary;
          }
        }
      }
    }
    // box-shadow: 0 rem(2) rem(20) rgba(87, 75, 144, .2);
  }
  .property-card_imginfomobile {
    height: 100%;
    div {
      place-items: flex-start;
    }
  }
  .property-card__imginfo {
    position: absolute;
    padding: 20px 12px 20px 20px;
    display: flex;
    justify-content: space-between;
    // z-index: 1;
    pointer-events: none;
    .property-card_discountchip {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      -moz-box-pack: justify;
      z-index: 1;
      pointer-events: none;
      bottom: 21px;
      right: 0px;
      svg {
        position: absolute;
      }
      .additional_width {
        width: 152px;
      }
      span {
        z-index: 99;
        width: 132px;
        color: #FFF;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        padding-top: 3px;
        .savetext {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.12px;
          text-align: center;
          margin: 0px;
          height: 11px;
        }
        .pricetext {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.14px;
          text-align: center;
        }
      }
    }
    @media (min-width: 600px) {
          // width: 29.764%;
          padding-bottom: 0px;
    }

    .property-card__imginfo__tags {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
      z-index: 1;
    }
    @media (max-width: 600px) {
      width: 100%;
      padding: 0.9375rem;
    }

    // @media (min-width: 1460px) {
      width: 100%;
      padding: 20px;
      padding-top: 14px;
      padding-bottom: 0px;
    // }
  }

  .property-card_wishlist-marker {
    &:active {
      transform: scale(.9);
    }
    transition: .3s ease-in-out;
    font-family: $primary-font;
    position: relative;
    border: none;
    cursor: pointer;
    outline: none;
    // width: rem(40);
    // height: rem(40);
    border-radius: 50%;
    background: none;
    display: flex;
    z-index: 1;
    margin-right: 2px;
    .property-card_wishlist-marker-icon {
      &.property-card_wishlist-marker-icon__selected {
        path {
          stroke: #DA1414;
        }
      }
    }
  }
  .property-card_slider-container, .swiper-container {
    .swiper-button-prev, .swiper-button-next {
      background-color: var(--color-white);
      width: rem(32);
      height: rem(32);
      box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, .2);
      border-radius: 50%;
      opacity: 0;
      @media only screen and (min-width: 600px) and (max-width: 900px) {
        opacity: unset;
      }
      transition: 0.2s ease-in-out;
      &::after {
        font-size: 12px;
        color: var(--color-black);
        font-weight: bolder;
      }
    }
    position: relative;
    overflow: hidden;
    border-top-left-radius: rem(20);
    border-bottom-left-radius: rem(20);
    z-index: unset;
  }

  .property-card_sticker {
    display: none;
    padding: rem(3) rem(10);
    position: absolute;
    bottom: rem(15);
    left: rem(15);
    z-index: 1;
    color: $color-purple;
    font-family: $primary-font;
    font-weight: 500;
    font-size: rem(12);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    // font-variant: small-caps;
    line-height: 1rem;
    border-radius: rem(6);
    background-color: $color-white;
  }
  .property-card_mobile-attrs {
    position: absolute;
    bottom: rem(15);
    display: grid;
    // column-gap: rem(15);
    grid-template-columns: max-content auto;
    padding-left: rem(15);
    // padding-right: rem(15);
    width: 100%;
    align-items: flex-end;
    pointer-events: none;
    .property-card_discountchip {
      display: flex;
      justify-content: flex-end;
      svg {
        position: absolute;
      }
      .additional_width {
        width: 152px;
      }
      span {
        z-index: 1;
        width: 132px;
        color: #FFF;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        padding-top: 3px;
        .savetext {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.12px;
          text-align: center;
          margin: 0px;
          height: 11px;
        }
        .pricetext {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.14px;
          text-align: center;
        }
      }
    }
    &_grsinfo {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .grs-rating {
        order: 3;
        border-radius: 10px;
        padding: 3px 15px;
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(2px);

        .property-card_grs-rating_number {
          padding: 0;
          display: flex;
          align-items: center;
        }
      }
      .property-card_import_source {
        order: 1;
      }
    }
    .property-card_amenities {
      margin-bottom: 5px;
    }
  }
  .swiper-container {
    /*.property-card_image */
    .swiper-pagination.swiper-pagination-bullets {
      .swiper-pagination-bullet {
        opacity: 1;
        background-color: $color-white;
        &.swiper-pagination-bullet-active-main {
            background-color: $color-cn-pink;
        }
      }
    }
    * {
      outline: transparent;
    }
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // width: rem(250);
    height: 100%;
    border-top-left-radius: rem(20);
    border-bottom-left-radius: rem(20);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    img {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
    .slick-dots {
      bottom: rem(5);
      li {
        margin: 0;
        button::before {
          background-color: $color-subtle-gray;
        }
        &.slick-active {
          button::before {
            background-color: $color-white;
          }
        }
      }
    }
    .slider-nav-control {
      transition: 0.2s ease-in-out;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(24);
      height: rem(24);
      box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, 0.2);
      background-color: $color-white;
      border-radius: 50%;
      z-index: 1;
      &.slick-next {
        right: rem(10);
        .slider-nav-control_icon {
          transform: rotate(-90deg) translateY(rem(1));
        }
      }
      &.slick-prev {
        left: rem(10);
        .slider-nav-control_icon {
          transform: rotate(90deg) translateY(rem(1));
        }
      }
    }
    .slick-slide {
      padding: 0;
      background: linear-gradient(35.88deg, rgba(0, 0, 0, 0.5) 8.86%, rgba(0, 0, 0, 0) 41.14%);
    }
  }
  .property-card_content-wrapper {
    padding: 20px 20px 20px 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    .property-card_content {
      color: $color-gray-font;
      font-size: 1rem;
      .property-card_name {
        // width: 75%;
        // @media (min-width: 1460px) {
          width: 100%;
        // }
        @media (max-width: 600px) {
          width: 100%;
        }
        &.property-card_name-full {
          width: 100%;
        }
        &__wrapper{
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        .property-card_name__type {
          position: relative;
          display: flex;
          align-items: center;
          gap: 15px;

          .property-card_name__type__name {
            position: relative;
            border: 0.3px solid #64748b;
            box-sizing: border-box;
            border-radius: 5px;
            display: inline-flex;
            padding: 4px 10px;
            margin: 10px 0;

            .property-card_name__type__name__title {
              font-weight: 400;
              font-size: 14px;
              line-height: 1;
              letter-spacing: 0.01em;
              color: #64748b;
            }
            .property-card_type-stars {
              display: inherit;
              margin-left: rem(5);

              path {
                fill: #efb008;
                fill-opacity: 1;
              }
            }
          }

          .property-card_name__type__info{
            position: relative;
            display: flex;
            align-items: center;
            gap: 16px;

            &__amenity{
              position: relative;
              display: flex;
              align-items: center;
              .property-card_services_top_icon{
                  position: unset;
                  place-items: center;
                  display: grid;
                  color: unset;
                  font-size: unset;
                  font-weight: unset;
                  line-height: unset;
                  width: 24px;
                  height: 24px;
                  text-align: unset;
                  flex-grow: unset;
                  flex-shrink: unset;
                  flex-basis: unset;
                  letter-spacing: unset;
                  padding: unset;
                  border-radius:unset;
                  border: unset;
                  background: unset;
              }
              svg{
                flex-shrink: 0;
                path{
                  fill: $color-slate-grey;
                }
              }

              &__info{
                position: relative;
                font-size: 14px;
                line-height: 1.2;
                letter-spacing: 0.01em;
                font-weight: 400;
                color: $color-dark-grey;
                margin-left: 7px;
              }

              &::after{
                position: absolute;
                content: '';
                width: 1px;
                height: 20px;
                background: $color-light-gray-input;
                right: -8px;
              }

              &:last-child{
                  &::after{
                    display: none;
                  }
              }

              &:first-child{
                svg{
                  margin-left: -2px;
                }
                .property-card_name__type__info__amenity__info{
                  margin-left: 3px;
                }
              }

              &:nth-child(2){
                svg{
                  margin-left: -5px;
                }
                .property-card_name__type__info__amenity__info{
                  margin-left: 2px;
                }
              }
            }

          }

          &.property-card_with_additional_headlines_count {
                display: none;
          }
        }

        .property-card_rating {
          position: absolute;
          right: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 10px;

          .react-loading-skeleton {
            width: rem(100);
          }
          .property-card_grs-rating {
            display: flex;
            flex-direction: row-reverse;
            .property-card_grs-rating_container {
              align-items: flex-end;
              .property-card_grs-rating_reviews {
                display: flex;
                span:first-child {
                  margin-right: rem(5);
                }
              }
            }
          }
        }
        .property-card_rating-icon {
          margin-right: rem(5);
          path {
            fill: $color-black;
          }
          &.property-card_rating-icon__selected {
            path {
              fill: $color-primary;
            }
          }
        }
        color: $color-black;
        font-size: rem(18);
        font-weight: 600;
        line-height: 1.2;
        display: grid;
        grid-template-columns: 1fr max-content;
        span {
          height: 22px;
          overflow: hidden;
          display: flow-root;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.property-card_name__labelled {
          column-gap: rem(0);
          max-width: 75%;
          @media(min-width: 600px) and (max-width: 750px) {
            max-width: 65%;
          }
          @media(min-width: 1090px) and (max-width: 1350px) {
            max-width: 60%;
          }
        }
        &.property-card_name--small{
          max-width: 100%;
        }
        .property-card_sticker {
          display: block;
          position: initial;
          border-style: solid;
          border-color: $color-purple;
          border-width: rem(1);
        }
      }
      .property-card_additional-headlines {
        display: none;
        align-items: center;
        margin-top: rem(5); // 10
        color: $color-subtle-grey;
        font-family: $primary-font;
        font-size: rem(13);
        line-height: rem(20);
        letter-spacing: 0.02em;
      }
      /* .property-card_services {
                margin-top: rem(12);

                .react-loading-skeleton {
                    width: rem(50);
                }
                display: flex;
                .property-card_service {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    &:not(:last-child) {
                        padding-right: rem(10);
                        border-right: rem(1) solid $color-light-gray-input;
                    }
                    &:not(:first-child) {
                        padding-left: rem(10);
                    }
                }
            } */
      .property-card_services {
        position: relative;
        order: 4;
        cursor: ew-resize;
        &_top ::-webkit-scrollbar {
          display: none;
        }
        &_top > * {
          pointer-events: auto;
        }
        &_top {
          position: relative;
          gap: 15px;
          grid-row-gap: 5px;
          margin-top: 10px;
          display: grid;
          grid-template-columns: repeat(6, 0fr);
          grid-gap: 10px;
          overflow-x: auto;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          scrollbar-width: none;
          pointer-events: none;
          cursor: ew-resize;

          &_icon {
            position: relative;
            place-items: center;
            color: #5C6AC4;
            font-size: 10px;
            font-weight: 500;
            line-height: 150%;
            width: 78px;
            text-align: center;
            flex: 0 0 calc(50% - 10px);
            letter-spacing: 0.1px;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            gap: 1px;
            flex-shrink: 0;
            border-radius: 18px;
            border: 1px solid #5C6AC4;
            background: #F0F8FF;
            cursor: ew-resize;
            svg {
                path {
                    fill: $color-dark-grey;
              }
            }
          }
        }
      }
      // .property-card_services-first {
      //   display: flex;
      // }
      .property-card_activities {
        order: 4;
      }
      .property-card_promo-section {
        &.promo-section_christmas {
          .property-card_promo-label {
            background-color: #1c4028;
          }
        }
        &.promo-section_valentines {
          .property-card_promo-label {
            background-color: #f35597;
          }
        }
        .property-card_promo-label {
          display: flex;
          justify-content: center;
          align-items: center;
          width: rem(136);
          height: rem(22);
          margin-top: rem(17);
          background-color: $color-black;
          border-radius: rem(6);
          font-family: $primary-font;
          font-weight: 500;
          font-size: rem(12);
          color: $color-white;
          text-transform: uppercase;
          line-height: rem(16);
          .promo-ribbon_content {
            margin-left: rem(5);
          }
        }
      }
    }

    .property-card_footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      overflow: hidden;
      font-weight: 400;
      color: $color-black;
      gap: 13px !important;
      // @media (min-width: 1460px) {
        margin-top: 0 !important;
      // }
      @media (max-width: 600px) {
        // margin-top: 0 !important;
      }

      &--small{
        overflow: unset;
      }

      .property-card_footer_left {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .property-card_services {
          position: relative;

          &_top {
            position: relative;
            gap: 15px;
            grid-row-gap: 5px;
            margin-top: 10px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &_icon {
              position: relative;
              place-items: center;
              color: #5C6AC4;
              font-size: 10px;
              font-weight: 500;
              line-height: 150%;
              width: 78px;
              text-align: center;
              flex: 0 0 calc(50% - 10px);
              letter-spacing: 0.1px;
              padding: 4px 8px;
              justify-content: center;
              align-items: center;
              gap: 1px;
              flex-shrink: 0;
              border-radius: 18px;
              border: 1px solid #5C6AC4;
              background: #F0F8FF;
          }
        }
        }
      }
      .property-card_activities {
        max-height: initial;
        line-height: initial;
        overflow: initial;
        // margin-bottom: rem(10);
        .property-card_activities_rail {
          padding-bottom: initial;
          overflow: inherit;
          flex-wrap: wrap;
        }
      }
      .property-card_price--unavailable {
        max-width: rem(195);
        place-items: flex-end;
      }
      .property-card_price {
        display: flex;
        flex-direction: column;
        font-family: $primary-font;
        font-size: 1rem;
        text-align: right;
        width: 100%;
        @media (max-width: 350px) {
          width: 130%;
        }
        .react-loading-skeleton {
          width: rem(200);
        }

        &--small{
          position: relative;
        }

        .property-card_price-discount {
          position: relative;
          margin-top: rem(5);
          margin-bottom: rem(5) !important;
          // display: flex;
          display: grid;
          grid-template-columns: auto auto;
          grid-gap: 5px;
          align-items: center;
          padding: rem(3) rem(10) rem(3) rem(7);
          border-radius: rem(6);
          background-color: $color-vibrant-pink2;
          grid-column: 1 / span 3;
          justify-self: flex-end;
          font-family: $primary-font;
          font-weight: 600;
          font-size: rem(14);
          line-height: rem(21);
          letter-spacing: 0.02em;
          color: $color-white;
          text-transform: uppercase;

          .promo-ribbon__hover {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -100%;
            font-weight: 600;
            font-size: 8px;
            line-height: 1.5;
            letter-spacing: 0.01em;
            color: $color-sea-green;
            background: url("/static/img/message.svg") no-repeat center center;
            background-size: contain;
            padding: 5px 8px 5px 5px;
          }
        }
        .property-card_price-original {
          color: $color-gray-font;
          text-decoration-line: line-through;
          // transform: translateY(1px);
          margin-right: rem(10);
          align-self: baseline;
        }
        .property-card_price-actual__active {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: $color-dark-grey;
          @media(max-width: 600px) {
            color: $color-black;
            font-weight: 400;
          }
        }
        .property-card_price-actual {
          font-size: rem(14);
          color: $color-black;
          align-self: baseline;
          display: flex;
          align-items: center;
          gap: 6px;
          margin-left: auto;
          &--small{
            gap: 0;
            align-items: flex-end;
            .strike-through-price {
              margin-right: 5px;
            }
          }

          .strike-through-price {
            min-height: 20px;
          }
          .strike-through-price__number {
            color: #E86993;
            font-family: $primary-font;
            font-weight: 500;
            font-size: 16px;
            // font-weight: 600;
            line-height: 120%;
            text-align: right;
            letter-spacing: 0.01em;
            text-decoration-line: line-through;
            vertical-align: -webkit-baseline-middle;
            vertical-align: bottom;
            @media(max-width: 600px) {
            font-size: 14px;
            }
          }

          .property-card_price-actual__rooms{
            color: $color-dark-grey;
            font-family: $primary-font;
            font-weight: 500;
            font-size: rem(14);
            line-height: rem(20);
            text-align: right;
            letter-spacing: 0.01em;
            min-height: 20px;
          }

          .property-card_price-actual__active {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: $color-dark-grey;
            margin-right: 3px;
            @media(max-width: 600px) {
              color: $color-dark-grey;
              font-weight: 400;
            }
          }
          .property-card_price-actual__active__night{
            font-family: 'Poppins';
            font-weight: 500;
          }
          @media (max-width: 750px) {
            flex-direction: column;
            gap: 0;
          }
          @media (max-width: 600px) {
            flex-direction: row;
          }
        }
        .property-card_price-info{
          font-size: 12px;
          font-weight: 400;
          color: $color-dark-grey;
        }
        .property-card_price-total {
          grid-column: 1 / span 3;
          color: $color-dark-grey;
          font-family: $primary-font;
          font-weight: 400;
          font-size: 14px;
          line-height: rem(20);
          text-align: right;
          letter-spacing: 0.01em;
          margin-top: rem(5);
          span {
            font-weight: 600;
            font-size: 20px;
            color: $color-black;
            @media (max-width: 600px) {
              font-size: 18px;
            }
          }
          .property-card_price-actual__havediscount {
            color: $color-black;
          }
        }
      }
    }
       .property-card_footer {
        width: 100%;
        // .walk-distance-landmark  {
        //     div {
        //       max-width:  140px;//210px;
        //     }
        // }
         .property-card_footer_left {
            width: 100%;
                .property-card_services {
                  display: flex;
                  flex-flow: column-reverse;
                  row-gap: 10px;
                .property-card_amenities {
                      position: relative;
                      width: auto;
                      overflow: unset;
                      max-height: unset;

                .property-card_amenities_rail {
                     grid-template-columns: 1fr 1fr 1fr;
                     grid-column-gap: 30px;
                     grid-row-gap: 15px;
                     .property-card_amenities_amenity-entity {
                       margin-right: 0;
                     }
                   }
                  }
                  @media screen and (min-width: 1100px) and (max-width: 1200px) {
                    .property-card_services_top {
                      grid-column-gap: 15px;
                    }
                    .property-card_amenities {
                      .property-card_amenities_rail {
                        grid-column-gap: 15px;
                      }
                    }
                  }
                }
              }
          }
  }
  .property-card_import_source--mobile {
    margin-bottom: 10px;
    width: fit-content;
    margin-left: auto;
  }
  .property-card_import_source {
    // z-index: 1;
    background: #555759;
    border-radius: 5px;
    color: $color-white;
    padding: rem(5) rem(10);
    font-weight: 700;
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    order: 3;
    margin-right: 11px;
    .property-card_import_source__red {
      color: red;
    }
    .property-card_import_source__green {
      color: green;
    }

    @media (max-width: 600px) {
      right: auto;
      left: 20px;
    }

    // @media (min-width: 1460px) {
      right: auto;
      left: 20px;
    // }
  }
  .property-card_editorpick {
    background: $color-cn-pink;
    border-radius: 5px;
    color: $color-white;
    padding: rem(5) rem(10);
    font-weight: 700;
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    display: flex;
    align-items: center;
    order: 2;

    svg {
      margin-right: 5px;
    }
  }
}

.property-card_services_top {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  flex-wrap: wrap;
  padding-top: 3px;
  padding-bottom: 5px;

  .property-card_services_top_icon {
      position: relative;
      display: grid;
      place-items: center;
      &:not(:last-child) {
          margin-right: 8px;
      }
  }
}

.property-card_services {
  position: relative;

  &_top {
    position: relative;
    gap: 3px;
    grid-row-gap: 3px;
    margin-top: 3px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 3px;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: ew-resize;

    &_icon {
      position: relative;
      place-items: center;
      color: #5C6AC4;
      font-size: 10px;
      font-weight: 500;
      line-height: 150%;
      width: 78px;
      text-align: center;
      flex: 0 0 calc(50% - 10px);
      letter-spacing: 0.1px;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 1px;
      flex-shrink: 0;
      border-radius: 18px;
      border: 1px solid #5C6AC4;
      background: #F0F8FF;
      cursor: ew-resize;
    }
  }
}

.property-card_type {
  .react-loading-skeleton {
    width: rem(240);
    height: rem(23);
  }
  display: flex;
  align-items: center;
  // font-variant: all-small-caps;
  text-transform: capitalize;
  font-size: rem(14);
  line-height: rem(21);
  letter-spacing: 0.02em;
  margin-bottom: rem(5);
  color: $color-black;
  .property-card_type-stars {
    // margin-top: rem(2);
    display: inherit;
    margin-left: rem(5);
    // opacity: 0.7;
    padding-bottom: rem(1);
    // margin-left: rem(6);
    path {
      fill: $color-black;
      fill-opacity: 1;
    }
  }
  .property-card_type-wrapper {
    display: flex;
    align-items: center;
    .property-card_additional-headlines {
      margin-top: 0;
    }
  }
}

.property-card_distance {
  display: flex;
  color: $color-black;
  font-family: $primary-font;
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(21);
  letter-spacing: 0.01em;
  transform: translateX(-5px);
  width: 75%;
  @media screen and (min-width: 1100px) and (max-width: 1170px) {
    width: 65%;
  }
  // @media (min-width: 1460px) {
    width: 100%;
  // }
  @media (max-width: 600px) {
    width: 100%;
  }
  .property-card_distance-icon {
    margin-right: rem(5);
    margin-left: rem(5);
    transform: translateY(1px);
    width: rem(8);
    flex-shrink: 0;

    path {
      fill: $color-black;
    }
  }
  .property-card_distance_content {
    display: -webkit-box;
    display: flex;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    align-self: center;
    word-break: break-all;
      .property-card_distance_container_divider {
        display: flex;
        align-items: center;
      }
  }
  .property-card_distance_fromcenter {
    max-width: 190px;
    overflow: hidden;
    display: flow-root;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .property-card_distance_withoutneighbour {
    max-width: 350px;
  }
  .property-card_distance_fromcenter {
    display: -webkit-inline-box;
    overflow: hidden;
    display: flow-root;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .property-card_distance_neighbour {
    font-weight: 600;
    display: flow-root;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.property-card_free-cancellation-holder {
  width: 100%;
}
.property-card_pay-letter-sticker {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: $color-pure-black;
  font-size: rem(10);
  border-radius: 19px;
  padding: 4px 8px 4px 8px;
  border: 1px solid $color-pure-black;
  background-color: var(--color-white);
  &.property-card_free-cancellation-sticker--large {
    position: initial;
    width:max-content;
  }

  &.property-card_pay-letter-sticker--overlayed {
    z-index: 1;
    width: min-content;
    white-space: nowrap;
    align-self: flex-end;
  }

  .property-card_free-cancellation-sticker_icon {
    min-width: rem(11);
    min-height: rem(9);
    margin-right: rem(7);
  }
}
.property-card_free-cancellation-sticker {
  display: flex;
  align-items: center;
  background-color: $color-light-sea-green;
  color: $color-sea-green;
  font-size: rem(10);
  padding: rem(4) rem(11);
  border-radius: 4px;
  width: fit-content;
  &.property-card_free-cancellation-sticker--large {
    position: initial;
    @media (min-width:1100px) and (max-width:1183px) {
      padding-left: 2px;
      padding-right: 2px;
      width: 20px;
      height: 20px;
        p {
            display: none;

        }
        svg {
          margin-right: 0px !important;
          margin-left: 4px;
         margin-top: 2px;
        }
    }
    @media (min-width:601px) and (max-width:669px) {
      padding-left: 2px;
      padding-right: 2px;
      width: 20px;
      height: 20px;
        p {
            display: none;

        }
        svg {
          margin-right: 0px !important;
          margin-left: 4px;
          margin-top: 2px;
        }
    }
  }
  .property-card_free-cancellation-sticker_icon {
    margin-right: rem(7);
  }
}
.property-card_breakfast-included-sticker {
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: $color-light-purple;
  color: $color-bright-purple;;
  font-size: rem(10);
  padding: rem(4) rem(8);
  border-radius: 4px;
  @media (min-width:1100px) and (max-width:1183px) {
    padding-left: 2px;
    padding-right: 2px;
    width: 20px;
    height: 20px;
    p {
        display: none;

    }
    svg {
      margin-right: 0px !important;
    }
  }
  @media (min-width:601px) and (max-width:669px) {
    padding-left: 2px;
    padding-right: 2px;
    width: 20px;
    height: 20px;
    p {
        display: none;

    }
    svg {
      margin-right: 0px !important;
    }
  }
  svg {
    width: 15px;
    margin-right: 5px;
    height: auto;
  }

  &.property-card_breakfast-included-sticker--large {
    position: initial;
    width: max-content;
  }
  .property-card_breakfast-included-sticker_icon {
    margin-right: rem(5);
  }
}

.property-card_grs-rating {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: rem(15);
  // column-gap: rem(7);
  font-family: $primary-font;

  .property-card_grs-rating_number {
    color: $color-white;
    background-color: $color-purple;
    padding: rem(10);
    font-weight: 600;
    font-size: rem(16);
    letter-spacing: 0.01em;
    line-height: 1.5;
    border-radius: 10px 10px 10px 5px;
  }
  .property-card_grs-rating_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .property-card_grs-rating_adjective {
      color: $color-purple;
      font-weight: 600;
      font-size: rem(16);
      line-height: 1.5;
      letter-spacing: 0.01em;
    }
    .property-card_grs-rating_reviews {
      color: $color-dark-grey;
      font-size: rem(14);
      line-height: 1.5;
      letter-spacing: 0.01em;
      font-weight: 400;
    }
  }
}

.property-card_activities {
  margin-top: rem(15);
  max-height: rem(29);
  line-height: rem(29);
  .property-card_activities_rail {
    gap: rem(5);
    padding-bottom: rem(5);
  }
  .property-card_activity {
    background: none;
    border: solid;
    border-width: 1px;
    border-color: $color-gray-font;
    color: $color-gray-font;
    font-size: 10px;
    font-weight: 500;
    line-height: rem(12);
    // background-image: linear-gradient(#f53959,#f35597);
    // background-size: 100% 200%;
    // background-position: 50% 100%;
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
    // font-weight: 600;
    padding: rem(4) rem(8);
    border-radius: rem(18);
    // border: rem(1) solid $color-primary;
    white-space: nowrap;
    @media (max-width: 600px) {
      white-space: unset;
    }
    opacity: 0;
    visibility: hidden;
    &.property-card_activity--visible {
      opacity: 1;
      visibility: visible;
    }
  }
}

.property-card_amenities,
.property-card_activities {
  width: 100%;
  overflow: hidden;
  max-height: rem(25);
  line-height: rem(25);
  .property-card_amenities_amenity-entity_icon-pool {
    path {
      stroke: $color-black;
    }
    rect {
      fill: $color-black;
    }
  }
}
.property-card_amenities_rail,
.property-card_activities_rail {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-flow: row nowrap;
}

.property-card_amenities_amenity-entity_popover {
  background: rgba(0, 0, 0, 0.7);
  color: $color-white;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  padding: rem(5) rem(10) !important;
  border-radius: rem(5);
}
.property-card_amenities_amenity-entity_popover2 {
  background: #F0F8FF;
  border: 1px solid #5C6AC4;
  color: #FFFFFF;
  padding: 0.3125rem 0.625rem !important;
  position: relative;
  top: -10px !important;
  border-radius: 5px;
  color: #5C6AC4;
  font-size: 10px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.1px;

  .property-card_amenities_amenity-entity_arrow-popover2 {
    bottom: -10px !important;
  }

  .property-card_amenities_amenity-entity_popover2_title {
    @media (max-width: 600px) {
      max-width: calc(100vw - 150px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__inside {
    &__row {
      position: relative;
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 10px;
      line-height: 1.5;
      letter-spacing: 0.01em;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }

      span {
        font-weight: 600;
      }
    }
  }
}

.property-card_amenities_amenity-entity {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  transition: 0.3s ease-out;
  opacity: 1;
  margin-right: rem(10);
  scroll-snap-align: end;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: normal;
  &:last-child {
    border-top-right-radius: rem(30);
    border-bottom-right-radius: rem(30);
  }
  // &.property-card_amenities_amenity-entity--loaded {
  //   opacity: 1;
  // }
  &.property-card_amenities_amenity-entity--hover {
    transition: opacity 0.3s ease-out;
    .property-card_amenities_amenity-entity_icon {
      opacity: 1;
    }
  }
  .property-card_amenities_amenity-entity_icon {
    opacity: 0.7;
    height: rem(25);
    svg {
      height: rem(25);
    }
  }
  .property-card_amenities_amenity-entity_name-container {
    overflow: hidden;
    transition: width 0.3s ease-out;
    scroll-snap-align: end;
    .property-card_amenities_amenity-entity_name {
      margin-top: rem(3);
      padding-left: rem(10);
      white-space: nowrap;
      font-family: $primary-font;
      font-weight: 500;
      font-size: rem(12);
      line-height: 1rem;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      color: #555759;
      vertical-align: middle;
      scroll-snap-align: end;
    }
  }
}

.property-card_mobile-attrs {
  .property-card_amenities_amenity-entity {
    .property-card_amenities_amenity-entity_icon {
      opacity: 1;
    }
  }
  .property-card_grs-rating {
    .property-card_grs-rating_number {
      color: $color-pure-black;
      background-color: unset;
      // color: $color-black;
    }
    .property-card_grs-rating_container {
      .property-card_grs-rating_adjective {
        color: $color-pure-black;
      }
      .property-card_grs-rating_reviews {
        color: $color-pure-black;
      }
    }
  }
  .property-card_amenities {
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    justify-content: flex-end;
    max-height: unset;
    line-height: unset;
    .property-card_amenities_rail {
      justify-content: flex-end;
      background: $color-white;
      box-shadow: inset 7.7px -7.7px 7.7px rgba(149, 149, 149, 0.1), inset -7.7px 7.7px 7.7px rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(15.4px);
      &:first-child {
        border-top-left-radius: rem(30);
        border-bottom-left-radius: rem(30);
      }

      &:last-child {
        border-top-right-radius: rem(30);
        border-bottom-right-radius: rem(30);
      }
    }
    circle,
    rect {
      fill: $color-white;
    }

    path {
      stroke: $color-white;
    }

    .property-card_amenities_amenity-entity {
      // background: rgba(196, 196, 196, .1);
      // box-shadow: inset 7.7px -7.7px 7.7px rgba(149, 149, 149, .1), inset -7.7px 7.7px 7.7px rgba(255, 255, 255, .1);
      // backdrop-filter: blur(15.4px);
      padding: rem(5);
      margin-right: initial;
      &:nth-child(1) {
        border-top-left-radius: rem(30);
        border-bottom-left-radius: rem(30);
      }
    }

    .property-card_amenities_amenity-entity_icon-img {
      max-width: rem(24);
      max-height: rem(24);
    }

    .property-card_amenities_amenity-entity_icon-parking {
      path {
        fill: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-washing-machine {
      .washing-mashine-outlined-rect-1,
      .washing-mashine-outlined-circle-1 {
        stroke: $color-white;
        fill: transparent;
      }
      path {
        stroke: $color-white;
        fill: $color-white;
      }
      .washing-mashine-outlined-rect-2,
      .washing-mashine-outlined-circle-2 {
        fill: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-services {
      path:last-child {
        fill: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-pool {
      .pool-outlined-path-3,
      .pool-outlined-path-4 {
        fill: $color-white;
        stroke: transparent;
      }
      path {
        stroke: $color-white;
      }
      rect {
        fill: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-bath {
      .bath-outlined-path-2 {
        fill: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-cigarette {
      .cigarette-crossed-path-3,
      .cigarette-crossed-path-4 {
        fill: $color-white;
        stroke: transparent;
      }
    }

    .property-card_amenities_amenity-entity_icon-lotos {
      path:last-child {
        fill: $color-white;
        stroke: transparent;
      }
    }

    .property-card_amenities_amenity-entity_icon-refrigerator,
    .property-card_amenities_amenity-entity_icon-car-and-airplane,
    .property-card_amenities_amenity-entity_icon-oven,
    .property-card_amenities_amenity-entity_icon-stove,
    .property-card_amenities_amenity-entity_icon-balcony,
    .property-card_amenities_amenity-entity_icon-spoon-and-fork {
      path {
        fill: $color-white;
        stroke: transparent;
      }
    }

    .property-card_amenities_amenity-entity_icon-toy {
      mask {
        path {
          stroke: initial;
        }
      }
      path,
      .toy-outlined-rect-1 {
        fill: $color-white;
        stroke: transparent;
      }
      .toy-outlined-rect-2,
      .toy-outlined-rect-3 {
        fill: transparent;
        stroke: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-wheelchair {
      path {
        fill: $color-white;
        stroke: transparent;
      }
      ellipse {
        fill: $color-white;
      }
    }

    .property-card_amenities_amenity-entity_icon-barbell {
      rect {
        stroke: $color-white;
      }
      mask {
        path {
          stroke: transparent;
        }
      }
      path {
        fill: $color-white;
        stroke: transparent;
      }
    }
  }
}

.tiny-tooltip-container {
  max-width: 150px;
  background: #0F1A2A;
  // background: rgba(0, 0, 0, 0.7);
  border-radius: rem(11);
  position: relative;
  top: -6px;
  .tiny-tooltip-text {
    padding: 0;
    text-align: center;
    color: $color-white;
    font-family: $primary-font;
    font-weight: 400;
    font-size: rem(14);
    line-height: 150%;
  }
  .tiny-tooltip-close {
    transform: scale(0.715);
    position: absolute;
    top: 2px;
    right: 7px;
  }
}
.tiny-tooltip-container-wide {
  max-width: 190px;
  .tiny-tooltip-text {
    font-size: 12px;
  }
  .tiny-tooltip-close {
    display: none;
    // transform: scale(0.68);
  }
}
.tiny-tooltip-container-reset-password {
  max-width: 300px;
  color: $color-shade-gray;
  background: $color-black;
  border-radius: 17px;
  transform: translateY(24px);
  .tiny-tooltip-text {
    font-size: 12px;
    line-height: 18px;
    padding-left: 12px;
    ul {
      li {
        text-align: left;
      }
    }
  }
  .tiny-tooltip-close {
    display: none;
  }
}
