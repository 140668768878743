//------------------- DESKTOP -------------------

.cn__CTA__completebooking__wrapper {
    position: absolute;
    bottom: 16px;
    left: 100px;
    @media screen and (min-width:1920px){
        left: calc(50% - 860px);
    }
}

.cn__CTA__completebooking{
    position: relative;
    padding: 3px;
    background: white;
    border-radius: 16px;
    transition: opacity 0.3s ease;
    opacity: 0;
    max-width: 213px;
    height: 223px;
    cursor: pointer;

    &.cn__CTA__completebooking--show{
        transition: opacity 0.5s ease;
        opacity: 1;
        z-index: 2;
    }


    &__inner{
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        &__img{
            position: relative;
            // width: 144px;
            height: 105px;
            flex-shrink: 0;
            // height: auto;
            overflow: hidden;
            border-radius: 14px 14px 0px 0px;
            .cn__image-default{
                width: 100%;
                height: 100%;
                object-position: center;
                filter: brightness(1);
            }
        }
        &__info{
            padding: 0 10px 10px;
            min-width: 150px;
            max-width: 400px;
            width: 100%;
            display: flex;
            padding-top: 10px;
            gap: 5px;
            &__header{
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                &__div{
                    flex: 1;
                    display: flex;
                    align-items: center;
                }

                &__propertyicon{
                    flex-shrink: 0;
                    margin-left: -5px;
                }

                &__title{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19.2px;
                    letter-spacing: -0.02em;
                    color: #000000;
                    margin-left: 6px;
                    pointer-events: none;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    max-width: 186px;
                    word-break: break-word;
                }

                &__close{
                    position: absolute;
                    flex: 0 0 auto;
                    cursor: pointer;
                    background: none;
                    outline: none;
                    border: none;
                    margin-left: 2px;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    right: 7px;
                    top: 7px;
                    svg {
                        path {
                            stroke: $color-white;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                        }
                    }
                }
            }
            &__description{
                margin-top: 5px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #797882;
                pointer-events: none;
            }
            &__book{
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
                &__wrapper{
                    position: relative;
                    display: flex;
                    align-items: center;
                    &__title{
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: $color-black;
                        margin-right: 5px;
                        //text-decoration: underline;
                    }
                    svg {
                        transform: rotate(-90deg);
                        width: 10px;
                        path{
                            stroke: $color-black;
                        }
                    }
                }
            }
        }
    }
}

//------------------- TABLET/MOBILE -------------------

@media screen and (max-width:1136px){
    .cn__CTA__completebooking__wrapper {
        // padding: 0 30px;
        left: 20px;
        bottom: 95px;
    }
}

//------------------- MOBILE -------------------

@media screen and (max-width:600px){
    .cn__CTA__completebooking__wrapper {
        width: 213px;
        bottom: 77px;
    }
    .cn__CTA__completebooking{
        width: 100%;
        max-width: 100%;

        &__inner{
            &__img{
            }
            &__info{
                padding: 10px 0 0 10px;
                &__header{
                    display: flex;
                    &__div{
                        width: calc(100% - 40px);
                    }
                    &__title{
                        font-size: 16px;
                        line-height: 19.2px;
                    }

                    &__close{
                        justify-content: flex-end;
                        @media screen and (max-width:311px){
                            justify-content: flex-start;
                        }
                        flex: 1;
                    }
                }
                &__description{
                    font-size: 14px;
                    margin-top: 5px;
                }
                &__book{
                    justify-content: flex-start;
                    margin-top: 12px;
                    &__wrapper{
                        &__title{
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}