@mixin innerWrapperLayoutContentMixin {
    .inner-wrapper-layout_content-area {

        // padding: rem(10);
        .services-inner_category,
        .amenities-inner_category {
            &:not(:first-child) {
                margin-top: rem(40);
            }

            .services_checkbox,
            .amenities_checkbox {
                &:first-child {
                    margin-top: rem(20);
                }

                .checkbox_label {
                    padding-left: 0;
                }
            }
        }

        .budget-inner {
            .price-widget {
                &.price-widget__set-own-active {
                    .price-widget_slider-container {
                        font-family: $primary-font !important;
                        height: 120px;
                        opacity: 1;
                        transition: height .3s ease-in-out, opacity .3s .3s ease-in-out;
                        .PrivateValueLabel-label-131{
                            font-size: rem(10);
                            font-family: $primary-font;
                        }
                    }

                    .price-widget_slots {
                        margin-top: 0;
                        height: 0;
                        opacity: 0;
                        transition: opacity .3s ease-in-out, height .3s .3s ease-in-out;
                    }
                }

                .price-widget_title {
                    font-size: rem(14);
                    color: $color-black;
                    text-align: center;
                    @media screen and (max-width:600px){
                        font-size: rem(14);
                    }
                }

                .price-widget_slider-container {
                    position: relative;
                    height: 0;
                    opacity: 0;
                    transition: opacity .3s ease-in-out, height .3s .3s ease-in-out;
                }

                .price-widget_slider-wrapper {
                    display: flex;
                    align-items: flex-end;
                    padding-top: 100px;
                    font-family: $primary-font !important;


                    .price-widget_slider {
                        .price-widget_slider-track {
                            background: $color-light-gray-input;
                            border-radius: rem(1);
                            height: rem(2);
                            top: rem(10);

                            &.price-widget_slider-track-1 {
                                background: $color-bright-purple;
                            }
                        }

                        .price-widget_slider-thumb {
                            border-radius: 50%;
                            width: rem(20);
                            height: rem(20);
                            background: $color-bright-purple;
                            border: rem(2) solid $color-white;
                            outline: none;
                            transition: width .2s ease-in-out, height .2s ease-in-out, transform .2s ease-in-out;

                            &.price-widget_slider-thumb__active,
                            &:hover {
                                width: rem(25);
                                height: rem(25);
                                transform: translateY(calc(50% - 15px));
                            }
                        }
                    }
                }

                .price-widget_toggle {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $color-black;
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 600;
                    // padding-left: rem(15);
                    padding: 16px 20px;
                    border-bottom: 1px solid #E9E9EB;

                    @media screen and (max-width:600px){
                        font-size: rem(14);
                        border-bottom: none;
                        padding: 6px 0;
                    }

                    .MuiSwitch-root{
                        margin: 0;
                    }
                }

                .price-widget_inputs {
                    display: flex;
                    justify-content: center;
                    margin-bottom: rem(25);

                    .price-widget_input-wrapper {
                        position: relative;

                        &:last-child {
                            margin-left: rem(20);
                        }

                        .price-widget_input-price {
                            position: absolute;
                            left: 5%;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 1;
                        }

                        .floating-input_input {
                            padding-left: 30%;
                        }
                    }
                }

                .price-widget_slots {
                    margin-top: rem(15);
                    opacity: 1;
                    transition: height .3s ease-in-out, opacity .3s .3s ease-in-out;
                    overflow: hidden;
                    padding: 0 10px;

                    .radio {
                        display: grid;
                        grid-template-columns: auto 1fr auto;
                        .radio_label {
                                display: flex;
                                row-gap: 2;
                                padding: 5px;
                        }
                        input {
                            grid-row: 1;
                            grid-column: 3;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .star-rating-inner {
            // .checkbox:first-child {
            //     .checkbox_label {
            //         padding-top: 0;
            //     }
            // }
            .checkbox_label {
                width: 100%;
            }
            .checkbox_label-text {
                display: grid;
                width: 100%;
                grid-template-columns: minmax(70px, 1fr) 1fr;
                align-items: center;
            }
            .star-rating_stars {
                display: flex;
            }
            .star-rating-inner_icon {
                path {
                    fill: $color-black;
                    fill-opacity: 1;
                }
            }

            .star-rating_text {
                display: inline-block;
                //margin-right: rem(10);
            }
        }

        .accommodation-type_checkbox {
            .checkbox_label {
                align-items: center;

                .accommodation-type_label-secondary {
                    color: $color-gray-font;
                    font-size: .7rem;
                }
            }
        }

        .cancellation-policy-inner {
            .cancellation-policy_checkbox {
                .checkbox_label {
                    padding-right: 0;

                    .cancellation-policy_more-info-icon {
                        margin-left: rem(10);
                    }
                }
            }
        }

        .checkbox {
            .checkbox_label {
                align-items: center;
                width: 100%;
                white-space: nowrap;
                padding-left: unset;
                .checkbox_label-text {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    // grid-template-columns: max-content auto;
                    column-gap: rem(10);
                    width: 88%;
                    align-items: center;
                    div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

// Veremchuk's styled components
@mixin tabs {
    .tabs {
        margin: 0;

        &.tab-vr__active {
            .tabs_container {
                &::before {
                    transform: translateX(50%);
                }
            }
        }

        &.tab-hotel__active {
            .tabs_container {
                &::before {
                    transform: translateX(-50%);
                }
            }
        }

        .tabs_container {
            position: relative;
            z-index: 0;

            &::before {
                display: block;
                content: '';
                width: 50%;
                height: 100%;
                position: absolute;
                background-color: $color-white;
                border-radius: rem(8);
                z-index: -1;
                transition: .3s ease-in-out;
            }

            display: grid;
            grid-template-columns: 50% 50%;
            justify-items: center;
            align-items: center;
            width: 100%;
            height: rem(37);
            background-color: $color-light-gray-input;
            border-radius: rem(10);
            border: 2px solid $color-light-gray-input;

            .tab:first-child {
                margin: 0;
            }

            .tab {
                .tab_slider {
                    display: none;
                }
            }
        }
    }
}

@mixin search {
    .search-field_wrapper {
        position: relative;

        &.search-field_wrapper__clear-active {
            .search-field.cn-autoComplete {
                .cn-input-group {
                    .primary-input.autoCompleteSearch {
                        padding-right: rem(50);
                        box-shadow: 0 rem(2) rem(4) rem(5) rgba(0, 0, 0, .04);
                    }
                }
            }
        }

        .search-icon,
        .close-icon_wrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }

        .search-icon {
            left: rem(13);
            width: 20px;
            height: 20px;
            path {
                fill: var(--color-dark-grey);
            }
            &.search-icon__highlighted {
                path {
                    fill: none;
                }
            }
        }

        .close-icon_wrapper {
            border: none;
            outline: none;
            display: flex;
            justify-content: center;
            align-items: center;
            right: rem(13);
            cursor: pointer;
            background-color: $color-shade-gray;
            border-radius: 50%;
            width: rem(24);
            height: rem(24);

            &:hover {
                background-color: $color-field-grey;
            }

            .close-icon {
                width: rem(10);
                height: rem(10);

                path {
                    stroke: $color-black;
                    stroke-width: rem(1.25);
                }
            }
        }

        /* .close-icon {
            right: rem(15);
            cursor: pointer;
        } */
        .search-field {
            &.cn-autoComplete {
                .cn-input-group {
                    .primary-input.autoCompleteSearch {
                        border: none;
                        background-color: $color-field-grey;
                        padding-left: rem(39);
                        padding-right: rem(20);
                        border-radius: initial;
                        box-shadow: none;
                        transition: .2s ease-in-out;
                        font-weight: 600;
                        font-size: rem(14);
                        letter-spacing: 0.01em;

                        &:focus {
                            border: none;
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
    }

}

@mixin datePicker {
    .filters-date-picker {
        background-color: $color-light-gray-input;
        border-radius: rem(34);
        cursor: pointer;

        .filters-date-picker_mobile-icon {
            margin-left: rem(10);
            transform: translateY(-4px);
            path {
                fill: var(--color-dark-grey);
            }
        }

        .DateRangePicker {
            .DateRangePickerInput {
                grid-template-columns: min-content min-content rem(90);

                .DateInput_input {
                    white-space: nowrap;
                    font-weight: 600;
                    font-size: rem(14);
                    line-height: rem(20);
                    letter-spacing: 0.01em;
                    &::placeholder {
                        font-size: rem(12);
                    }
                    &:placeholder-shown {
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        &.date-picker__selected {
            background-color: $color-field-grey;
            width: initial;
            // box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, .2);
            @media screen and (min-width:599px) {
                &:hover:not(.date-picker__active) {
                    background-color: $color-field-grey-hover;
                    border-radius: rem(34);
                }
            }

            &:active {
                background-color: $color-white;
            }

            .DateRangePicker {
                .DateRangePickerInput {
                    grid-template-columns: min-content min-content min-content;

                    .DateInput {
                        width: rem(72);
                    }
                }
            }
        }

        &.date-picker__active {
            position: relative;
            z-index: 2;
            background-color: $color-white;
            // border-radius: rem(15);
            box-shadow: 0 rem(2) rem(4) rem(5) rgba(0, 0, 0, .04);
        }

        width: initial;

        .DateRangePicker {
            .DateRangePickerInput {
                place-items: center;

                .DateInput:first-child,
                .DateInput:last-child {
                    margin: 0;
                }
            }
        }

        .DayPicker_transitionContainer {
            box-sizing: content-box;
            padding-bottom: rem(45);
        }

        .DayPickerNavigation_button {
            &.DayPickerNavigation_button__disabled {
                display: none;
            }
        }
    }
}

@mixin guestSelector {
    .guest-selector {
        padding-left: 0;
        padding-right: 0;
        background-color: $color-field-grey;
        transition: .3s ease-in-out;
        // background: $color-white;
        border-radius: rem(34);
        box-shadow: 0 rem(1) rem(2) rgba(36, 37, 38, .2);
        height: rem(46);
        white-space: nowrap;

        &.guest-selector__active {
            background-color: $color-white;
            // border-radius: rem(15);
            box-shadow: 0 rem(2) rem(4) rem(5) rgba(0, 0, 0, .04);
        }

        .guest-selector_track {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(20);
            letter-spacing: 0.01em;
            margin-left: rem(5);
            .guest-selector_room-info {
                @media (min-width: 360px) {
                    display: inline;
                }
            }
        }

        .guest-selector_toggle {
            display: none;
        }

        .filters-guest-selector_mobile-icon {
            // width: rem(24);
            // height: rem(24);
            margin-left: rem(10);
            path {
                fill: var(--color-dark-grey);
            }

            // path {
            //     stroke: $color-subtle-grey;
            // }
        }
    }
}

// end Veremchuk's styled components

@mixin filtersTabBarPrimary {
    .filters-tab-bar_primary {
        margin-top: rem(5); // rem(20);
        // box-shadow: 0 rem(2) rem(4) rem(5) rgba(0, 0, 0, .04);
        /*&.filters-tab-bar_primary__active {
            .search-field_wrapper {
                .search-field.cn-autoComplete {
                    .cn-input-group {
                        .primary-input.autoCompleteSearch {
                            box-shadow: 0 rem(2) rem(4) rem(5) rgba(0, 0, 0, .04);
                        }
                    }
                }
            }
        }*/

        .search-field_wrapper {
            margin-left: rem(20);
            margin-right: rem(20);

            .search-icon {
                &.search-icon__highlighted {
                    path {
                        fill: $color-primary;
                    }
                }
            }
        }

        @include search;
        @include datePicker;
        @include guestSelector;

    }
}

@mixin propertyCardMobile {
    .property-card {
        &.property-card__loading {
            .property-card_content-wrapper {
                .property-card_footer {
                    margin-top: rem(20);
                    .property-card_rating, .property-card_price {
                        width: rem(100);
                    }
                }
            }
        }
        // display: block;
        // height: auto;
        // .property-card_wishlist-marker {
        //     height: rem(40);
        //     width: rem(40);
        // }

        grid-template-columns: 100%;
        grid-template-rows: 220px auto;
        background-color: $color-white;

        &[data-source="[DIRECT_SEARCH]"] {
            background-color: var(--color-urinal-light);
        }

        .property-card_slider-container {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .property-card_image-slider {
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;
            }
            .swiper-container {
                z-index: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 15px;
                height: 240px !important;
                    .swiper-slide {
                        text-align: center;
                        font-size: 18px;
                        background: #fff;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                        height: 230px;
                      }

                      .swiper-slide img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }

                      .swiper-pagination{
                        .swiper-pagination-bullet {
                            background-color: $color-white;
                            opacity: unset;
                        }
                        .swiper-pagination-bullet-active-main{
                            background-color: $color-cn-pink;
                        }
                      }
            }
        }

        .property-card_content-wrapper {
            padding: rem(12) rem(15) rem(16) rem(15);

            .property-card_footer {
                display: grid;
                grid-template-columns: 1fr auto;
                justify-content: flex-end;
                margin-top: rem(10);
                grid-column-gap: 40px;

                .property-card_price {
                    .property-card_price-total {
                        justify-self: flex-end;
                        margin-left: auto;
                    }
                }
            }
        }

        .property-card_sticker {
            display: block;
            top: rem(24);
            right: rem(15);
            bottom: auto;
            left: auto;
        }

        .property-card_image-slider {

            &.react-loading-skeleton {
                height: 100%;
            }

            height: rem(220);
            border-radius: rem(20) rem(20) 0 0;

            img {
                height: rem(220);
                position: relative;
                z-index: -1;
                // filter: brightness(.7);
            }
        }

        .property-card_promo-section {
            .property-card_promo-label {
                margin-bottom: rem(10);
            }
        }

        /* .property-card_distance */
        .property-card_additional-headlines {
            margin: initial;

            .property-card_additional-headlines_bedrooms,
            .property-card_additional-headlines_bathrooms {
                display: flex;
                align-items: center;

                path,
                rect,
                circle {
                    /* fill: $color-subtle-grey;
                    stroke: $color-subtle-grey; */
                    fill: $color-gray-font;
                    stroke: $color-gray-font;
                }
            }
        }

        .property-card_footer {

            // margin-top: rem(7);
            .property-card_rating,
            .property-card_price {
                .react-loading-skeleton {
                    width: rem(100);
                }
            }

            .property-card_price {
                justify-self: flex-end;
                grid-template-columns: repeat(3, max-content);
                // grid-template-rows: repeat(2, auto);
                // column-gap: rem(15);
                align-items: flex-end;

                .property-card_price-original {
                    grid-column: 1 / 2;
                    margin: initial rem(5) initial initial;
                }

                .property-card_price-actual {
                    grid-column: 1 / span 3;

                    &.property-card_price-actual__discounted {
                        grid-column: 2 / 3;
                    }
                }

                .property-card_price-discount {
                    // grid-column: 3 / 4;
                    // grid-row: 1 / 2;
                    margin: initial initial rem(9) initial;
                }

                .property-card_price-total {
                    grid-column: 1 / span 3;
                    justify-self: flex-start;
                }
            }
        }
    }
}

@mixin filtersTabbarSecondary {
    .filters-tab-bar_secondary {
        animation: appear 1s forwards;
        opacity: 0;
        margin-top: rem(20);
        display: grid;
        grid-template-columns: repeat(4, max-content);
        grid-column-gap: rem(20);
        align-items: center;

        .drop-down-popover {
            .outer_info-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: rem(8) rem(16);
                font-size: rem(14);
                line-height: rem(18);
                font-weight: 400;
                // padding-left: rem(10);
                // padding-right: rem(10);
            }

            .drop-down-popover_track {
                .outer__active {
                    font-weight: 500;

                    .outer_info-container {
                        animation: appear .3s ease-in-out;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .outer_info-content {
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            /* @media (min-width: 930px) {
                                max-width: rem(85);
                            }
                            @media (min-width: 1300px) {
                                max-width: rem(110);
                            }
                            @media (min-width: 1480px) {
                                max-width: initial;
                            } */
                        }

                        @include clearableTag;

                        .clearable-tag {
                            margin-left: initial;
                            font-size: rem(14);
                        }
                    }
                }

                .sort-outer {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        margin-right: rem(5);
                    }
                }
            }

            background: none;
            border: 1px solid $color-field-grey;
            outline: none;
            display: flex;
            color: $color-black;
            font-family: $primary-font;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            // padding: rem(10);
            border-radius: rem(30);
            // line-height: rem(40);
            // transition: .15s ease-in-out;

            .drop-down-popover_toggle {
                margin-left: rem(10);

                .carret-icon {
                    transition: .3s ease-in-out;
                    transform: rotate(0);
                    width: rem(10);
                }
            }

            &:hover {
                // background-color: $color-light-gray-input;
                border: 1px solid $color-black;
            }

            &.drop-down-popover__active {
                // font-weight: 500;
                // background-color: $color-light-gray-input;
                border: 1px solid $color-champagne-pink;
                background-color: $color-champagne-pink;

                .drop-down-popover_toggle {
                    .carret-icon {
                        transform: rotate(0);
                    }
                }

                .drop-down-popover_track {
                    .outer__active {
                        .outer_info-container {
                            .clearable-tag {
                                background-color: $color-champagne-pink;
                            }
                        }
                    }
                }
            }

            &.drop-down-popover__disabled {
                cursor: initial;
                opacity: .6;
                border: 1px solid $color-light-gray;
                background-color: $color-light-gray;
            }
        }
    }
}

@mixin amenityCollection {
    .amenities-collection {

        // margin-right: rem(20);
        // display: flex;
        // flex-wrap: wrap;
        .swiper-container.swiper-container-horizontal {
            margin-left: initial;
            margin-right: initial;
            width: 100%;
            margin: initial;
            z-index: 0;
            .swiper-button-next,
            .swiper-button-prev {
                color: $color-black;
                outline: 0;

                &::after {
                    margin-top: rem(10);
                    font-weight: bold;
                    font-size: initial;
                    background: $color-white;
                    box-shadow: 0 0 rem(20) rem(30) $color-white;
                }

                &.swiper-button-disabled {
                    opacity: 0;
                    color: $color-black;
                }
            }

            .swiper-button-prev {
                transform: translateX(-12px);
            }

            .swiper-button-next {
                transform: translateX(12px);
            }

            .swiper-wrapper {
                .swiper-slide {
                    width: fit-content;
                }
            }
        }
    }
}

@mixin filtersTabbarAmenities {
    .filters-tab-bar_amenities {
        transition: .3s ease-in-out;

        @include amenityCollection;
    }
}

@mixin headerWrapper {

    .header-wrapper_container {
        height:100%;
    }

    .header-wrapper {
        .li--currency {
            visibility: visible;
        }

        .filters-tab-bar {
            &.filters-tab-bar_primary-filters__search-focus {
                .filters-tab-bar_primary {
                    grid-template-columns: 1fr;
                    width: 100%;
                    .filters-date-picker,
                    .guest-selector,
                    .filters-tab-bar_separator {
                        display: none;
                    }
                }
            }

            &:not(.filters-tab-bar_primary-filters__search-focus) {
                .filters-tab-bar_primary-filters {
                    .filters-tab-bar_primary {
                        .search-field_wrapper {
                            .search-field.cn-autoComplete .cn-input-group {
                                .primary-input.autoCompleteSearch {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }

            &:not(.filters-tab-bar_primary-filters__datepicker-focus) {
                .filters-tab-bar_primary-filters {
                    .filters-tab-bar_primary {
                        .filters-date-picker {
                            .DateRangePicker .DateRangePickerInput .DateInput_input {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }

            &:not(.filters-tab-bar_primary-filters__guestselector-focus) {
                .filters-tab-bar_primary-filters {
                    .filters-tab-bar_primary {
                        .guest-selector {
                            .guest-selector_track {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        .filters-tab-bar_primary-filters {
            display: grid;
            grid-template-columns: repeat(2, min-content);
            column-gap: rem(10);
            position: absolute;
            top: -90px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;

            .filters-tab-bar_minimized__subbtn {
                border-radius: rem(6);
                border: rem(1) solid var(--color-grey);
                display: grid;
                grid-template-columns: repeat(2, min-content);
                column-gap: rem(5);
                align-items: center;
                color: $color-black;
                letter-spacing: 0.01em;
                background-color: #fff;

                font-size: rem(14);
                font-weight: 600;
                padding: rem(4) rem(12) rem(4) rem(7);
                font-family: $primary-font;
                align-self: center;
                cursor: pointer;

                path {
                    fill: var(--color-dark-grey);
                }
            }

            .filters-tab-bar_maximized {
                opacity: initial;
                display: block;
                height: auto;
                width: rem(555);
            }

            .filters-tab-bar_primary {
                .search-field_wrapper {
                    * {
                        text-overflow: ellipsis;
                    }
                }
                .filters-date-picker,
                .guest-selector {
                    opacity: 0;
                    animation: appear .5s forwards;
                    &.guest-selector__active,
                    &.date-picker__active {
                        box-shadow: none;
                    }
                }
            }
        }

        &.header-wrapper__min {
            // .header-logo {
            //     .cnword {
            //         transform: translateY(-100%);
            //         opacity: 0;
            //     }
            // }

            // .header-nav {
            //     transform: translateY(-100%);
            //     opacity: 0;
            // }

            .header {
                height:100%;
                z-index: 2;
                .li--currency {
                    visibility: hidden;
                }
            }

            .filters-tab-bar_primary-filters {
                top: -70px;
                .filters-tab-bar_maximized {
                    opacity: initial;
                    display: block;
                    height: auto;
                    width: rem(555);
                }
            }

            .filters-tab-bar_maximized {
                height: 0;
                opacity: 0;
            }

            .filters-tab-bar_minimized {
                top: calc((((88px - 46px) / 2) + 46px) * (-1));
                opacity: 1;
            }

            .main_toolbar {
                opacity: 0;
                height: 0;
            }

            .filters-tab-bar_maximized,
            .filters-tab-bar_secondary,
            .filters-tab-bar_amenities {
                display: none;
            }
        }
    }

    .header {
        position: relative;
        z-index: 3;

        .header-logo {
            width: rem(133);
            .cnword {
                transition: transform .3s ease-in-out, opacity .3s ease-in-out;
                transform: translateY(0);
                opacity: 1;
            }
        }

        .header-nav {
            transition: transform .3s ease-in-out, opacity .3s ease-in-out;
            transform: translateY(0);
            opacity: 1;

            .header-nav_blog-menu-item, .header-nav_help-menu-item {
                display: none;
            }
        }
    }

    .filters-tab-bar {
        padding-left: rem(20);
        padding-right: rem(50);
        border-bottom-left-radius: rem(20);
        border-bottom-right-radius: rem(20);
        position: relative;
        z-index: 2;

        .filters-tab-bar_separator {
            justify-self: center;
            width: rem(1);
            height: rem(22);
            background-color: var(--color-subtle-grey);
        }

        .filters-tab-bar_minimized {
            cursor: pointer;
            position: absolute;
            z-index: 3;
            top: rem(-120);
            opacity: 0;
            left: 50%;

            transform: translateX(-50%);
            border: none;
            border-radius: rem(15);
            outline: none;
            font-family: $primary-font;
            font-size: 1rem;
            color: $color-black;
            text-align: center;
            transition: top .3s ease-in-out, opacity .3s ease-in-out;
            display: grid;
            justify-content: space-evenly;
            align-items: stretch;
            column-gap: rem(15);
            background-color: transparent;
            grid-template-columns: 1fr;

            @media (min-width:1301px) and (max-width:1370px) {
                font-size: 0.75rem;
            }
            @media (min-width: 790px) {
                grid-template-columns: 75% max-content;
            }

            @media (min-width: 1360px) {
                grid-template-columns: 85% max-content;
            }

            @media (min-width: 790px) and (max-width: 1300px)  {
                width: 60%;
            }

            @media (max-width: 930px) {
                left: 45%;
            }
            @media (max-width: 700px) {
                .filters-tab-bar_minimized-wrapper {
                    max-width: 340px;
                }
                left: 42%;
            }

            .filters-tab-bar_minimized-wrapper {
                border-radius: rem(34);
                background-color: $color-white;
                display: grid;
                grid-template-columns: max-content minmax(10%,auto) 0.9375rem max-content 0.9375rem max-content;
                align-items: center;
                justify-content: space-evenly;
                line-height: rem(44);
                padding-left: rem(15);
                padding-right: rem(20);
                border: rem(1) solid $color-field-grey;
                box-shadow: 0 rem(6) rem(14) rem(-6) rgba(24, 39, 75, .12);

                // &:hover {
                //     background-color: #DADDE0;
                // }

                & > .filters-tab-bar_minimized-term {
                    text-overflow: ellipsis;
                }

                & > * {
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: left;
                    @media (max-width:900px) {
                        font-size: 0.75rem;
                    }
                }

                .search-icon__highlighted {
                    margin-right: rem(10);
                    width: rem(20);
                    height: rem(20);
                    path {
                        fill: $color-slate-grey;
                    }
                }

                .filters-tab-bar_minimized-dates__nodates {
                    color: $color-subtle-gray;
                }

                .filters-tab-bar_minimized-guests {
                    text-align: left;
                }
            }

            .filters-tab-bar_minimized__subbtn {
                display: none;
                @media (min-width: 790px) {
                    // width: rem(44);
                    // height: rem(44);
                    border-radius: rem(25);
                    border: rem(1) solid $color-field-grey;
                    display: grid;
                    grid-template-columns: repeat(2, min-content);
                    column-gap: rem(5);
                    align-items: center;
                    color: $color-black;
                    letter-spacing: 0.01em;
                    background-color: #fff;

                    font-size: rem(12);
                    line-height: 150%;
                    padding: rem(3) rem(12) rem(3) rem(7);
                    // background-color: $color-light-gray-input;

                    path {
                        fill: $color-black;
                    }
                }
            }
        }

        .filters-tab-bar_maximized {
            transition: height .3s ease-in-out;
            height: rem(50); // 100px;
            opacity: 1;

            .tabs {
                margin: auto;
                max-width: rem(330);
            }

            .filters-tab-bar_primary {
                grid-template-columns: 1fr repeat(2, min-content);
                border-radius: rem(34);
                // background-color: $color-field-grey;
                border: rem(1) solid var(--color-grey);
                // box-shadow: 0 rem(6) rem(14) rem(-6) rgba(24, 39, 75, .12);
                background-color: $color-white;

                // grid-column-gap: rem(10);
                .search-field_wrapper {
                    margin: 0;

                    // border-right: rem(1) solid $color-shade-gray;
                    // .search-icon {
                    //     left: rem(10);
                    //     // &.search-icon__highlighted {
                    //     //     path {
                    //     //         // fill: $color-white;
                    //     //     }
                    //     // }
                    // }

                    .search-field.cn-autoComplete {
                        .cn-input-group {
                            .primary-input.autoCompleteSearch {
                                border-radius: rem(34);
                                height: rem(50);
                                transition: .3s ease-in-out;

                                background-color: $color-white;
                                &:hover:not(:focus) {
                                    // background-color: $color-field-grey-hover;
                                    cursor: pointer;
                                }
                            }
                            .cn__autocomplete-suggestions {
                                margin-top: rem(10);
                            }
                        }
                    }
                }

                .filters-date-picker {
                    border-radius: rem(34);
                    box-shadow: none;
                    // border-right: rem(1) solid $color-shade-gray;
                    background-color: $color-white; // $color-field-grey;
                    &.date-picker__selected {
                        background-color: inherit;
                        &:hover:not(.date-picker__active) {
                            background-color: inherit;
                        }
                    }
                    // &.date-picker__selected {
                    //     box-shadow: none;
                    // }
                }

                .guest-selector {
                    height: auto;
                    // border-top-left-radius: initial;
                    // border-bottom-left-radius: initial;
                    // border-top-right-radius: rem(15);
                    // border-bottom-right-radius: rem(15);
                    box-shadow: none;
                    background-color: $color-white;
                    &:hover:not(.guest-selector__active) {
                        background-color: inherit;// $color-field-grey-hover;
                        // border-radius: rem(15);
                    }

                    .carret-icon {
                        opacity: 0;
                    }
                }
            }
        }

        .filters-tab-bar_secondary {
            grid-template-columns: 1fr;
            margin-top: rem(20); // rem(7);

            // .simplebar-wrapper {
            //     .simplebar-content {
            //         // display: flex;
            //         .drop-down-popover:not(:last-child) {
            //             // margin-right: rem(15);
            //         }
            //     }
            // }
            &:not(.filters-tab-bar_secondary--touched) {
                .swiper-button-prev {
                    opacity: 0;
                }
            }
            .swiper-container.swiper-container-horizontal {
                margin: initial;

                .swiper-button-next,
                .swiper-button-prev {
                    color: $color-black;
                    outline: 0;

                    &::after {
                        font-weight: bold;
                        font-size: initial;
                        background: $color-white;
                        box-shadow: 0 0 rem(20) rem(30) $color-white;
                    }

                    &.swiper-button-disabled {
                        opacity: 0;
                        color: $color-black;
                    }
                }

                .swiper-button-prev {
                    transform: translateX(-12px);
                }

                .swiper-button-next {
                    transform: translateX(12px);
                }

                .swiper-wrapper {
                    .swiper-slide {
                        width: fit-content;
                        margin: 0;
                    }
                }
            }
        }

        @include tabs;
        @include filtersTabBarPrimary;
    }

    .main_toolbar {
        transition: height .2s ease-in-out, opacity .2s ease-in-out;
        height: rem(79);
        overflow: hidden;
        opacity: 1;
    }

    .main_layout {
        .scrollable-property-container {
            .property-card {
                // height: rem(300); // rem(270);
                // @media only screen and (min-width: 600px) and (max-width: 1171px) {
                //     height: rem(358);
                //   }
                // box-shadow: 0px 6px 14px -6px rgb(24 39 75 / 12%), 0px 10px 32px -4px rgb(24 39 75 / 10%);
                box-shadow: inset 0px 0px 0px 1px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
                .property-card_image-slider {
                    img {
                        height: rem(238); // rem(270);
                        // border-top-left-radius: rem(20);
                        // border-bottom-left-radius: rem(20);
                    }
                }
            }
        }
    }

    // .property-card_image-slider {
    //     img {
    //         height: rem(170);
    //     }
    // }
}

@mixin commonMixin {
    &.main_desktop,
    &.main_tablet,
    &.main_mobile {
        .info-banner-bar {
            &.info-banner-bar--tabbar-minimized {
                .cn__CTA__freecancellation__wrapper {
                    &.cn__CTA__freecancellation--show {
                        box-shadow: 0 rem(30) rem(20) rem(-20) rgba(0, 0, 0, .15);
                    }
                }
            }
        }
        // &.main--cta-progress-active {
        //     .main_center {
        //         transition: .1s ease-in;
        //     }
        //     .info-banner-bar {
        //         padding-bottom: rem(10);
        //     }
        // }

        // &.main--cta-freecancellation-active {
            // .main_center {
            //     transition: none; // .1s ease-in;
            // }
            // .info-banner-bar {
            //     height: 0;
            //     &.info-banner-bar--tabbar-minimized {
            //         .cn__CTA__freecancellation__wrapper {
            //             transform: translateY(-200%);
            //         }
            //     }
            //     .cn__CTA__freecancellation__wrapper {
            //         opacity: 0;
            //         &.cn__CTA__freecancellation--show {
            //             opacity: 1;
            //             transform: translateY(0);
            //         }
            //     }
            // }
        // }

        // .info-banner-bar {
            // position: fixed;
            // background-color: $color-white-background;
            // z-index: 2;
            // padding-bottom: 0;
        // }

        // .main_center {
        //     transition: .3s .6s ease-in;
        // }

        .header-wrapper {
            position: fixed;
            z-index: 2;
            background-color: $color-white;
            top: 0;
        }

        .extra-bar {
            * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width:600px){
                    white-space: unset;
                }
            }
            opacity: 0;
            display: grid;
            grid-template-columns: 1fr max-content;
            column-gap: rem(10);
            align-items: flex-end;
            justify-content: space-between;
            padding-top: rem(20);
            padding-left: rem(20);
            padding-right: rem(20);
            padding-bottom: rem(5);
            animation: appear .3s ease-in-out forwards;

            .extra-bar_acc-count {
                color: $color-dark-grey;
                letter-spacing: 0.02em;
                font-size: rem(14);
                line-height: rem(18);
                font-weight: 400;
            }

            .extra-bar_location {
                color: $color-black;
                font-weight: 600;
                font-size: rem(23);
                // line-height: rem(26);
                // margin-top: rem(8);
            }
        }
    }

    &.main_desktop,
    &.main_tablet {
        @include headerWrapper;
        .extra-bar {
            align-items: center;
            .extra-bar_location {
                font-size: rem(23);
            }
        }

        .property-card {
            .property-card_content-wrapper {
                .property-card_content {
                    display: flex;
                    flex-direction: column;

                    .property-card_name {
                        order: 1;
                    }
                    .property-card_type {
                        order: 2;
                        .property-card_type-wrapper {
                            .property-card_additional-headlines {
                                display: flex;
                            }
                        }
                    }
                    .property-card_distance {
                        & + .property-card_additional-headlines {
                            display: none;
                        }
                        order: 3;
                    }
                    .property-card_services {
                        order: 4;
                    }
                }
            }
        }
    }

    /*&.main_tablet,
    &.main_mobile {
        .info-banner-bar {
            transition: padding .3s ease-in;
            &.info-banner-bar--mobile {
                transition-delay: 0s;
                padding-top: rem(171);

                &.info-banner-bar--tabbar-minimized {
                    transition-delay: .3s;
                    padding-top: rem(80);
                }
            }

            &.info-banner-bar--tablet {
                transition-duration: .1s;
                padding-top: rem(200);

                &.info-banner-bar--tabbar-minimized {
                    transition-duration: .3s;
                    padding-top: rem(75);
                }
            }
        }
    }*/
}

@mixin clearableTag {
    .clearable-tag {
        // margin-left: rem(10);
        margin: 0 rem(5);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-light-gray-input;
        padding: 0 rem(10);
        line-height: rem(26);
        height: rem(26);
        border-radius: rem(100);

        .clearable-tag_content {
            display: inline-block;
            margin-right: rem(5);
        }

        .clearable-tag_btn-icon {
            cursor: pointer;

            // margin-left: rem(10);
            path {
                stroke: $color-black;
                stroke-width: rem(1.5);
            }
            // &:hover {
            //     path {
            //         stroke: $color-black;
            //     }
            // }
        }
    }
}

@mixin extendedFiltersAccordeon {
    .filters-tab-bar_extended {
        .drop-down-collection {

            .drop-down {
                font-family: $primary-font;
                font-weight: 400;
                color: $color-black;
                font-size: 1rem;
                background: none;
                border: none;
                outline: none;
                width: 100%;
                border-bottom: rem(1) solid $color-light-gray-input;

                .drop-down_toggle {

                    &:hover,
                    &.drop-down__active {
                        background-color: inherit;
                    }

                    .drop-down_toggle {
                        padding-left: rem(10);
                    }

                    padding-left: initial;
                    padding-right: initial;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: inherit;

                    .outer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: rem(7) rem(10);
                        &.outer__active {
                            background-color: $color-champagne-pink;
                            border-radius: rem(33);
                            .outer_info-container {
                                .clearable-tag {
                                    background-color: $color-champagne-pink;
                                }
                            }
                            .outer_icon{
                                path{
                                    fill: $color-black;
                                }
                            }
                        }

                        .outer_icon {
                            margin-right: rem(10);
                            width: rem(24);
                            &.neighborhood-icon {
                                * {
                                    stroke: $color-dark-grey;
                                }
                            }
                            &.hotel-chain-icon {
                                path {
                                    fill: $color-slate-grey;
                                }
                            }
                        }

                        .sort-outer {
                            svg {
                                display: none;
                            }
                        }
                    }

                    .outer__active {
                        font-weight: 500;

                        .outer_info-container {
                            display: flex;
                            align-items: center;

                            .outer_info-content {
                                white-space: nowrap;
                                overflow: hidden;
                                max-width: rem(230);
                                text-overflow: ellipsis;
                            }

                            @include clearableTag;
                        }
                    }
                }

                .inner-wrapper-layout {
                    @include innerWrapperLayoutContentMixin;
                    background-color: inherit;
                    padding-bottom: rem(20);

                    .inner-wrapper-layout_content-area {
                        .star-rating-inner {
                            .checkbox_label-text {
                                margin-left: 0;
                                grid-template-columns: 80px 1fr;
                            }
                        }
                        .amenities-inner_category,
                        .services-inner_category {

                            .services_checkbox,
                            .amenities_checkbox {
                                .checkbox_label {
                                    padding-left: rem(10);
                                }
                            }

                            &:not(:first-child),
                            &:first-child {

                                .services_checkbox,
                                .amenities_checkbox {
                                    &:first-child {
                                        margin-top: rem(10);
                                    }
                                }

                                margin-top: rem(10);
                            }

                            .category_title {
                                padding-left: rem(10);
                                font-size: rem(14);
                            }
                            .show_more {
                                outline: 0;
                                cursor: pointer;
                                font-weight: 500;
                                font-size: 1rem;
                                line-height: 1.25rem;
                                padding-left: rem(19);
                                color: var(--color-custom-blue-light);
                                font-family: "Poppins", sans-serif;
                                svg {
                                    margin-left: 5px;
                                    path {
                                        stroke: var(--color-custom-blue-light);
                                    }
                                }
                            }
                            .expand_more_icon {
                                svg {
                                    transform: rotate(271deg)
                                }
                            }
                        }

                        .bathrooms-and-beds-type-inner {
                            .bathrooms-and-beds-type-inner__section-checkboxes {
                                .checkbox {
                                    padding-left: 0 !important;
                                    padding-right: 0 !important;
                                    &:not(:last-child) {
                                        margin-bottom: 0;
                                    }
                                    // width: rem(80);
                                    .checkbox_label {
                                        padding-left: inherit !important;
                                        padding-right: inherit !important;
                                        flex-direction: initial;
                                        .checkbox_checkmark {
                                            order: initial;
                                        }
                                        .checkbox_label-text {
                                            padding-right: 0;
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }

                        .checkbox {
                            border-radius: 1.125rem;
                            padding-left: 0.625rem;
                            padding-right: 0.625rem;

                            .checkbox_label {
                                flex-direction: row-reverse;
                                justify-content: space-between;
                                width: 100%;
                            }

                            &:not(:last-child) {
                                margin-bottom: rem(4);
                            }
                            // &:hover {
                            //     background: $color-Shade-gray;
                            // }
                            // &.checkbox--checked {
                            //     background-color: $color-champagne-pink;
                            // }
                        }
                    }
                }
            }
        }
    }
}

@mixin mapAmenityBar {
    .amenity-bar {
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(20px);
        // background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0) 100%);
        align-items: center;
        row-gap: rem(15);
        .poi-types-collection {
            .poi-types_type {
                font-size: rem(14);
                letter-spacing: 0.01em;
                line-height: 150%;
                cursor: pointer;
                padding: rem(7) rem(15) rem(7) rem(10);
                border-radius: rem(50);
                background-color: $color-white;
                color: $color-prussian-blue;
                border: 1px solid $color-prussian-blue;
                outline: 0;
                display: grid;
                align-items: center;
                column-gap: rem(5);
                grid-template-columns: repeat(2, auto);
                transition: background-color .3s;
                font-family: $primary-font;
                &.poi-types_type--active {
                    background-color: $color-slate-grey;
                    border-color: $color-white;
                    color: $color-white;
                    svg {
                        path {
                            fill: $color-white;
                        }
                    }
                }
            }
        }
    }
}