@mixin searchResultsDesktopDefault {
    .search-results {
        margin: auto;
        overflow: hidden;

        .cn__currency__desktop.cn__currency__desktop--dark {
            border-color: var(--color-grey);
            &:hover {
                border: none;
            }
        }

        .search-results-map {
            position: relative;
        }

        @include mapAmenityBar;
        .amenity-bar {
            position: absolute;
            top: 0;
            left: 0;
            // height: 0;
            align-items: center;
            width: 100%;
            // background-color: rgba(255, 255, 255, .7);
            background-color: transparent;
            backdrop-filter: none;
            display: flex;
            grid-template-columns: max-content 1fr max-content;
            column-gap: rem(28);
            padding-top: rem(15);

            // padding-left: rem(15);
            // padding-right: rem(20);

            // &.amenity-bar--active {
            //     height: rem(70);
            //     .amenity-bar_hide-btn,
            //     .amenity-bar_close-btn {
            //         margin-top: 0;
            //     }
            // }


            grid-column-gap: 12px;
            grid-row-gap: 50px;
            justify-content: space-between;
            align-items: flex-start;
            padding: 20px;

            .amenity-bar_hide-btn {
                display: grid;
                place-content: center;
                margin-left: rem(15);

                .active-filters-counter {
                    display: grid;
                    grid-template-columns: repeat(2, max-content);
                    column-gap: rem(10);
                    align-items: center;
                    padding: rem(10) rem(15) rem(10) rem(10);

                    .active-filters-counter_icon {
                        width: rem(30);
                        height: rem(30);

                        path {
                            fill: $color-black;
                        }
                    }
                }
                .amenity-bar_icon {
                    transform: rotate(90deg);
                }
            }

            .amenity-bar_hide-btn,
            .amenity-bar_close-btn {
                width: rem(34);
                background-color: $color-white;
                border: none;
                box-shadow: 0 0 0 rem(1) $color-prussian-blue;//rgba(0, 0, 0, .15);
                border-radius: rem(10);
                font-family: $primary-font;
                font-size: 1rem;
                cursor: pointer;
                outline: 0;
            }
            .amenity-bar_close-btn-text {
                font-size: 1rem;
                line-height: 125%;
                height: 20px;
                font-weight: 500;
                text-transform: none;
                color: $color-black;
            }

            .amenity-bar_close-btn {
                min-width: rem(34);
                width: auto;
                padding: rem(8) rem(13);
                display: flex;
                grid-template-columns: repeat(2, max-content);
                text-align: center;
                //margin-right: rem(15);
                margin-right: 0;
                flex-shrink: 0;
                // column-gap: rem(18);

                .amenity-bar_icon {
                    width: .8rem;
                    height: .8rem;
                    align-self: center;
                    //display: none;
                    margin-left: 15px;
                }

                @include tablet {

                    background: #FFFFFF;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    padding: 11.602px;

                    .amenity-bar_icon{
                        margin-left: 0;
                    }

                 }
            }

            .amenity-bar__openbutton{
                visibility: hidden;
            }


            .amenity-bar_icon {
                path {
                    stroke: $color-black;
                }
            }

            @include amenityCollection;

            .amenities-collection,
            .poi-types-collection {
                &.amenities-collection__active {
                    .swiper-pagination-progressbar {
                        display: block;
                        top: initial;
                        bottom: 0;
                        height: rem(2);

                        .swiper-pagination-progressbar-fill {
                            background-color: $color-purple;
                        }
                    }
                }
                //width: calc(100vw - 588px);
                width: 100%;

                .swiper-container {
                    position: unset;
                }

                .swiper-button-prev.swiper-button-prev.swiper-button-prev {
                    top: 0;
                    left: 0;
                    transform: translateX(0);
                    margin: 0;
                    height: 100%;
                }
                .swiper-button-next.swiper-button-next.swiper-button-next {
                    top: 0;
                    right: 0;
                    transform: translateX(0);
                    margin: 0;
                    height: 100%;
                }
                .swiper-button-next.swiper-button-next.swiper-button-next.swiper-button-next::after,
                .swiper-button-prev.swiper-button-prev.swiper-button-prev.swiper-button-prev::after {
                    background: none;
                    box-shadow: none;
                    margin-top: 0;
                }

                .swiper-slide {
                    display: grid;
                    place-content: center;
                    padding: rem(5);
                    width: max-content;
                }

                .swiper-pagination-progressbar {
                    display: none;
                }
            }
            .amenities-collection {
                //grid-column: 1 / span 3;
                width: auto;
                max-width: 100%;
                overflow: hidden;
            }

            .amenities-chip {
                color: $color-black;
                background-color: $color-champagne-pink;
                margin-top: 0;
                // box-shadow: 0 rem(2) rem(5) rgba(0, 0, 0, .1);
            }

            height:0px;
            padding:0px;
            margin-right:20px;
            width:auto;
            margin-top: 20px;
            z-index: 1;
        }

        &.search-results_desktop {
            .map-container {
                .map-zoom-controls {
                    transform: none;
                    bottom: rem(20);
                    top: initial;
                }
            }
            //&:not(.search-results_desktop_default) {
                /*&.sidebar__hidden{
                    .map-container {
                        &.map-search__search {
                            .search_container {
                                top: rem(184);
                                margin-left: rem(60);
                            }
                        }
                    }
                    .amenity-bar {
                        &.amenity-bar--active {
                            & + .map-container {
                                &.map-search__search {
                                    .search_container {
                                        top: rem(204);
                                    }
                                }
                            }
                        }
                    }
                }*/
                //.map-container {
                    /*.map-control {
                        &:not(.map-zoom-out) {
                            margin-top: 0;
                            margin-bottom: 0;
                        }

                        &.map-pin {
                            top: rem(124);
                            margin-left: rem(17);
                        }

                        &.map-viewtype {
                            top: rem(170);
                            margin-left: rem(17);
                        }

                        &.map-reset-btn {
                            visibility: hidden;
                        }
                    }*/

                    /*.search_container {
                        // top: rem(124);
                        margin-left: rem(120);
                        // margin-top: rem(10);
                        max-width: rem(535);

                        .search_input {
                            padding-top: rem(12);
                            padding-bottom: rem(12);
                        }
                    }*/
                //}
            //}
            &.search-results_desktop_default {
                max-width: rem(1920);
                // grid-template-columns: 433px 1fr;
                // column-gap: rem(20);

                padding-top: rem(192);// rem(254);
                // padding-top: rem(262);
                // padding-top: rem(238);
                padding-left: rem(20);
                padding-right: rem(20);

                .header-wrapper__min + .search-results-map {
                    .map-container {
                        top: 88px;
                    }
                }
                .search-results-map {
                    border-radius: rem(20);
                    height: calc(100vh - 207px /*254px*/);
                    // height: calc(100vh - 262px);
                    // height: calc(100vh - 238px);

                    .map-container {
                        position: fixed;
                        // width: calc(100% - 730px);
                        width: rem(433);

                        /*&.map-search__found {
                            .map-control {
                                &.map-pin {
                                    .map-pin_place-name {
                                        max-width: rem(165);
                                    }
                                }
                            }
                        }

                        .map-control {
                            &.map-fullscreen {
                                top: rem(11);
                            }
                        }*/
                        .map-info-container.map-mobile {
                            max-width: rem(400);
                        }

                        .search_container {
                            max-width: rem(350);
                        }
                    }
                }

                .header-wrapper {
                    &.tabbar-root_header-toolbar__hidden {
                        .header-wrapper_container {
                            .DesktopHeader_logo {
                                width: rem(65);
                                overflow: hidden;
                                svg g:first-of-type {
                                    display: none;
                                }
                            }

                            .DesktopHeader_nav {
                                .DesktopHeader_nav_inner li:first-of-type {
                                    display: none;
                                }
                            }

                            .filters-tab-bar {
                                &:not(.filters-tab-bar_primary-filters__search-focus) {
                                    .filters-tab-bar_primary-filters .filters-tab-bar_maximized .filters-tab-bar_primary {
                                        padding-right: rem(12);
                                        grid-template-columns: 133px repeat(4, min-content);

                                        .search-field_wrapper .search-field.cn-autoComplete .cn-input-group {
                                            .primary-input {
                                                padding-right: rem(10);
                                            }
                                        }
                                    }
                                }

                                .filters-tab-bar_primary-filters {
                                    grid-template-columns: auto 1fr;
                                    left: rem(75);
                                    right: rem(130);
                                    transform: none;

                                    .filters-tab-bar_primary {
                                        margin-top: 0;

                                        .search-field_wrapper {
                                            margin: 0;
                                            .search-field.cn-autoComplete .cn-input-group .primary-input.autoCompleteSearch {
                                                background: none;
                                                min-width: rem(440);
                                                height: 48px;
                                            }
                                        }

                                        .filters-date-picker {
                                            width: auto;
                                            background: none;
                                        }

                                        .guest-selector {
                                            background: none;
                                            box-shadow: none;

                                            .guest-selector_track {
                                                margin: 0;
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                }
                            }

                            .header-toolbar {
                                transition-duration: .3s;
                                transition-delay: .3s;
                                display: flex;
                                align-items: center;
                                gap: rem(10);
                            }
                        }
                    }

                    // width: 100%;
                    // width: calc(100vw - 100px);
                    width: 100vw;
                    // max-width: rem(1340);
                    grid-column: 1 / span 3;

                    position: fixed;
                    z-index: 2;
                    background-color: $color-white;
                    top: 0;
                    left: 0;

                    padding-left: rem(20);
                    @media (min-width: 1920px) {
                        padding-left: 0;
                    }

                    padding-right: rem(20);
                    .header-wrapper_container {
                        margin: auto;
                        max-width: rem(1340);

                        .header-toolbar {
                            transition-behavior: allow-discrete;
                            display: none;
                        }

                        .filters-tab-bar_primary-filters {
                            transition: left .3s;
                            transform: none;
                            left: 25%;

                            @media (min-width: 1380px) {
                                left: 30%;
                            }

                            @media (min-width: 1520px) {
                                left: 33%;
                            }
                        }
                    }
                }

                .header {
                    .header-grid {
                        .logoImg {
                            grid-column-start: 1;
                        }

                        .header-nav {
                            grid-column: 5/17;
                        }
                    }
                }

                @include headerWrapper;

                .filters-tab-bar {
                    padding: 0;
                    opacity: 0;
                    animation: appear .5s ease-in-out forwards;

                    .filters-tab-bar_maximized {
                        user-select: none;
                        display: grid;
                        grid-template-columns: rem(680) 1fr;
                        column-gap: rem(10);

                        .ant-switch_container {
                            display: none;
                            column-gap: rem(20);
                            @media (min-width: 1135px) {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                            }
                        }
                        .ant-switch {
                            display: flex;
                        }

                        .ant-switch_labelPlacementStart {
                            margin: 0;
                        }

                        .ant-switch_label {
                            display: inline-block;
                            margin-right: rem(10);
                            font-family: $primary-font;
                            color: $color-black;
                            font-weight: 500;
                            font-size: rem(14);
                            line-height: rem(16);
                            letter-spacing: 0.01em;
                        }

                        // max-width: rem(660);
                        .filters-tab-bar_primary {
                            display: grid;
                            margin-top: 0;
                            grid-template-columns: 146px repeat(4, min-content);
                            align-items: center;
                            // .search-field_wrapper .search-field.cn-autoComplete .cn-input-group .primary-input.autoCompleteSearch {
                            //     font-weight: 500;
                            //     font-size: rem(13);
                            // }
                            // .filters-date-picker .DateInput_input,
                            // .guest-selector .guest-selector_track {
                            //     white-space: nowrap;
                            //     font-weight: 500;
                            //     font-size: rem(13);
                            //     line-height: rem(20);
                            //     letter-spacing: 0.01em;
                            // }
                            // .guest-selector {
                            //     .guest-selector_track {
                            //         .guest-selector_room-info {
                            //             display: inline;
                            //         }
                            //     }
                            //     .guest-selector_toggle {
                            //         display: none;
                            //     }
                            // }
                        }
                    }
                }

                @include filtersTabbarSecondary;
                @include filtersTabbarAmenities;

                .filters-tab-bar_amenities {
                    height: 48px;
                }

                .main {
                    z-index: 0;
                }

                .main_layout {
                    .scrollable-property-container {
                        padding-top: 0;

                        .scrollable-property-container_wrapper,
                        .scrollable-property-container__loader {
                            padding-left: 0;
                            padding-right: 0;

                            // @media (min-width: 1460px) {
                                // display: grid;
                                // grid-template-columns: repeat(auto-fill, minmax(300px, 433px));
                                // column-gap: rem(20);

                                @include propertyCardMobile;

                                .property-card {
                                    height: auto;
                                    // max-width: rem(434);
                                    // background-color: $color-white;
                                    // box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
                                    .property-card_slider-container {
                                        .swiper-button-prev, .swiper-button-next {
                                          top: 50%;
                                        }
                                      }
                                    // .property-card_image-slider {
                                    //     img {
                                    //         border-top-left-radius: initial;
                                    //         border-bottom-left-radius: initial;
                                    //     }
                                    // }

                                    // .property-card_wishlist-marker {
                                    //     width: rem(24);
                                    //     height: rem(24);
                                    // }

                                    .property-card_mobile-attrs {
                                        .property-card_amenities_amenity-entity {
                                            background: none;
                                        }
                                    }
                                }
                            // }
                        }
                    }
                }

                &.header-wrapper__min {
                    padding-top: rem(70);

                    .header-wrapper {
                        height: 88px;

                        .filters-tab-bar_secondary{
                            display: none;
                        }
                    }

                    .search-results-map {
                        height: calc(100vh - 103px);
                        // height: calc(100vh - 67px);
                    }

                    // Commented due FE-964 keep minimized header on default
                    // .scrollable-property-container {
                    //     padding-top: rem(160);
                    // }
                    .main {
                        padding-top: rem(18);
                    }
                    .filters-tab-bar_amenities {
                        display:none
                    }
                }
            }

            &.search-results_desktop_map_expanded {
                .amenity-bar {
                    .amenity-bar_hide-btn {
                        visibility: hidden;
                        width: rem(34);
                        height: rem(50);
                    }

                    .amenities-collection {
                        padding: 0 30px;
                        margin-left: rem(50);
                        width: calc(100vw - 687px);
                        margin-top: -5px;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                        .swiper-container.swiper-container-horizontal {
                            .swiper-slide {
                                &:last-child {
                                    margin-right: rem(15) !important;
                                }
                            }
                        }
                    }
                }

                &.sidebar__hidden {
                    grid-template-columns: 0 1fr;

                    .filters-side-bar {
                        transform: translateX(-150%);
                    }

                    // .map-container {
                    //     .map-control {
                    //         &.map-pin, &.map-viewtype {
                    //             margin-left: rem(20);
                    //         }
                    //         // &.map-pin {
                    //         //     top: rem(110);
                    //         // }
                    //     }
                    //     /*&.map-search__search {
                    //         .search_container {
                    //             top: rem(110);
                    //             margin-left: 0;
                    //         }
                    //     }*/
                    // }

                    .amenity-bar {
                        .amenity-bar_hide-btn {
                            width: auto;
                            height: auto;
                            visibility: visible;
                        }

                        .amenity-bar__openbutton{
                            visibility: visible;
                            background-color: $color-white;
                            border: none;
                            border-radius: 11px;
                            font-family: "Poppins", sans-serif;
                            font-size: 1rem;
                            cursor: pointer;
                            outline: 0;
                            display: grid;
                            place-content: center;
                            margin-left: 0.9375rem;
                            width: max-content;
                            flex-shrink: 0;

                            &__inside{
                                width: max-content;
                                box-shadow: 0 0 0 0.0625rem #27364b;
                                border-radius: 11px;
                                display: flex;
                                place-content: center;
                                column-gap: 5px;
                                padding: 5px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                align-items: center;
                                svg{
                                    width: 12px;
                                    position: relative;
                                    left: 1px;

                                    path{
                                        stroke-width: 2px;
                                    }
                                }
                            }
                        }

                        .amenity-bar__filtersinfo{
                            display: grid;

                            .active-filters-counter{
                                display: grid;
                                grid-template-columns: repeat(2, max-content);
                                align-items: center;
                                position: relative;
                                left: -2px;

                                .active-filters-counter_icon {
                                    width: 22px;
                                    height: auto;
                                    margin-right: 2px;

                                    path {
                                        fill: #242526;
                                    }
                                }
                                .active-filters-counter_label{
                                    position: relative;
                                    top: 1px;
                                    font-size: rem(14);
                                }
                            }
                        }

                        // .amenities-collection,
                        .poi-types-collection {
                            //width: calc(100vw - 322px);
                            width: 100%;
                        }

                        .amenities-collection {
                            position: relative;
                            padding: 0 30px;
                            margin-left: 0;
                            width: calc(100vw - 259px);
                            margin-top: -5px;
                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            .swiper-container.swiper-container-horizontal {
                                .swiper-slide {
                                    &:first-child {
                                        margin-left: rem(0);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.search-results_tablet_map {
            .amenity-bar {
                .amenity-bar_close-btn {
                    min-width: 36px;
                    width: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;

                    >span {
                        margin-right: unset;
                        margin-left: unset;
                    }
                }
            }
            .swiper-button-prev.swiper-button-prev.swiper-button-prev {
                display: none;
                // top: 0;
                // left: 0;
                // transform: translateX(0);
                // margin: 0;
                // height: 100%;
            }
            .swiper-button-next.swiper-button-next.swiper-button-next {
                display: none;
                // top: 0;
                // right: 0;
                // transform: translateX(0);
                // margin: 0;
                // height: 100%;
            }
        }
    }
}

@mixin desktopMixin {
    &.main_desktop {
        .header-wrapper {
            max-width: rem(730);
        }

        .main_toolbar {
            .toolbar_wrapper {
                display: flex;
                padding-top: rem(20);
                padding-bottom: rem(20);
            }
        }

        .info-banner-bar {
            // transform: translateY(-100px);
            // padding-top: rem(100);
            .cn__CTA__loadingsrp__wrapper, .cn__CTA__bookinfo1__wrapper {
                margin-bottom: rem(20);
            }
        }

        .extra-bar {
            padding: 0;
        }

        &.main_desktop_default {
            // box-shadow: 0 rem(2) rem(20) rgba(87, 75, 144, .3);
            // padding-left: rem(20);
            .main_layout_no-properties{
                padding-top: 17px;
            }
            .DateRangePicker_picker {
                left: -365px !important;
            }

            .toolbar_wrapper {
                justify-content: flex-end;

                .sort-by-selector {
                    margin-right: rem(50);
                }
            }
        }

        &.main_desktop_map_hidden {
            .header-wrapper {
                position: initial;

                .main_toolbar {
                    max-width: rem(795);

                    .toolbar_wrapper {
                        justify-content: space-between;

                        .show-map-trigger {
                            margin-left: rem(20);
                        }

                        .sort-by-selector {
                            margin-right: rem(20);
                        }
                    }
                }
            }

            .header.header-transparent {
                .header-grid {
                    @media (min-width: 1700px) {
                        .header-nav {
                            margin-right: 10%;
                        }
                    }
                }
            }

            .main_layout {
                &:not(.main_layout_no-properties) {
                    max-width: rem(1200);

                    @media (min-width: 1320px) {
                        display: grid;
                        grid-template-columns: 64.1% 1fr;

                        .aside-property-detail {
                            display: block;
                        }
                    }

                    @media (min-width: 1380px) {
                        grid-template-columns: 67.1% 1fr;
                    }
                }

                .main_center {
                    overflow: hidden;
                    min-width: rem(550);
                }

                .scrollable-property-container {

                    &.scrollable-property-container__exists {
                        min-height: rem(456);
                    }

                    .scrollable-property-container_wrapper {
                        padding-left: initial;
                        padding-right: rem(50);
                        // padding-right: 3.125rem;
                    }

                    // .scrollable-property-container__loader {
                    //     height: initial;
                    //     position: absolute;
                    //     left: 50%;
                    //     top: 40%;
                    //     transform: translate(50%, 50%);
                    // }
                    .no-properties {
                        margin-top: rem(70);
                    }

                    padding-top: initial;
                    margin-left: rem(20);
                    width: 100%;
                    height: calc(100vh - 145px);
                    overflow-y: scroll;
                    padding-right: rem(17);
                }
            }
        }

        .filters-tab-bar {
            border-bottom: rem(1) solid $color-light-gray-input;
            padding-bottom: rem(30);
            padding-right: rem(50);
        }
    }
}